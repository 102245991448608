<template>
  <v-toolbar height="32" class="mb-0 mt-0" flat color="white">
    <v-toolbar-title>Tasks</v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <v-btn x-small color="primary" dark class="mb-2" @click="generateExcel">Generate Excel</v-btn>

    <v-btn x-small color="primary" dark class="mb-2 ml-2" @click="adjustSize">{{ adjustText }}</v-btn>

    <v-btn x-small color="primary" dark class="mb-2 ml-2" @click="addRow">Add Row</v-btn>
    <v-divider class="mx-4" inset vertical></v-divider>

    <v-file-input
      dense
      v-model="file"
      placeholder="Load excel file"
      label="File input"
      prepend-icon="mdi-paperclip"
      show-size
      @change="loadExcel"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">{{ text }}</v-chip>
      </template>
    </v-file-input>
    <v-divider class="mx-4" inset vertical></v-divider>

    <v-spacer></v-spacer>
    <v-btn x-small color="primary" dark class="mr-2 mb-2" @click="undoDelete"> UNDELETE </v-btn>
    <v-btn x-small color="primary" dark class="mb-2" @click="toggleWeight">{{ showWeight }}</v-btn>
    <AddColumn />
    <div class="eva__userDetails" @click="setProfileDialog">
      <img :src="require(`@/assets/user/${userType}.png`)" class="eva-navigation__profile-img" />
    </div>
  </v-toolbar>
</template>

<script>
import ExcelOperator from "../../utils/excel-operator";
import { mapState } from "vuex";
import FulfilmentsUtil from "../../utils/fulfilments-util";
import AddColumn from "@/components/AddColumn";
import { Auth } from "aws-amplify"; // Import AWS Amplify Auth module
import AuthComp from "../Auth.vue";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
// import PayPalButton from "../PayPalButton.vue";

const util = new FulfilmentsUtil();

export default {
  props: ["showWeight"],
  emits: ["handleShowWeight", "handleUndelete"],
  data: () => ({
    horizontalAdjusted: false,
    adjustText: "Fit On Page",
    heightScale: 0,
    widthScale: 0,
    file: null,
    authState: undefined,
  }),
  components: {
    AddColumn,
    AuthComp,
    // PayPalButton,
  },
  mounted() {
    // this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
    //   this.authState = authState;
    // });
  },
  computed: {
    userType() {
      if (this.isAuthenticated && this.subStatus == "active") {
        return "subscribed";
      } else if (this.isAuthenticated) {
        return "login";
      } else {
        return "user";
      }
    },
    subscriptionID() {
      return this.$store.getters.getSubID;
    },
    subStatus() {
      return this.$store.getters.getSubStatus;
    },
    isAuthenticated() {
      return this.$store.getters.getAuth == "signed-in" ? true : false;
    },
    stepsFulfillment() {
      return this.$store.getters.stepsFulfillment;
    },
    ...mapState(["statusTable"]),
    headers() {
      return this.statusTable.headers;
    },
    filteredHeaders() {
      // Add a checkbox header
      return this.statusTable.headers.filter((item) => {
        if (this.showWeight === "Show Weight") {
          return item.value !== "weight";
        } else {
          return true;
        }
      });
    },
    deliverables() {
      return this.statusTable.deliverables;
    },
    responsibles() {
      return this.statusTable.responsibles;
    },
    steps() {
      return this.statusTable.steps;
    },
    totalFulfillment() {
      return Math.round(this.$store.getters.totalFulfillment);
    },
  },
  methods: {
    checkSub() {
      this.$store.dispatch("setSubStatus", "suspended");
    },
    setProfileDialog() {
      this.$store.dispatch("toggleProfileDialog");
    },
    closeModal() {
      this.profileDialog = !this.profileDialog;
    },
    toggleProfile() {},
    goToSignIn() {
      this.$router.push("/auth");
    },
    signOut() {
      Auth.signOut();
    },
    generateExcel() {
      const excelOperator = new ExcelOperator(
        this.deliverables,
        this.steps,
        this.responsibles,
        this.stepsFulfillment,
        this.totalFulfillment
      );
      const passObject = {
        deliverables: this.deliverables,
        headers: this.headers,
        steps: this.steps,
        responsibles: this.responsibles,
        stepsFulfillment: this.stepsFulfillment,
        totalFulfillment: this.totalFulfillment,
      };
      excelOperator.saveAsExcel(passObject);
    },
    adjustSize() {
      const table = document.getElementsByTagName("table")[0];
      const tableWrapper = document.getElementsByClassName("v-data-table__wrapper")[0];
      if (this.adjustText === "Normal Size") {
        if (!this.horizontalAdjusted) {
          tableWrapper.style.width = tableWrapper.clientWidth * this.heightScale + "px";
        }
        table.style.transform = `scale(1)`;
        this.adjustText = "Fit On Page";
      } else {
        const containerWidth = tableWrapper.clientWidth;
        const containerHeight = tableWrapper.clientHeight;
        const scrollWidth = tableWrapper.scrollWidth;
        const scrollHeight = tableWrapper.scrollHeight;
        this.widthScale = containerWidth / scrollWidth;
        this.heightScale = containerHeight / scrollHeight;

        if (this.widthScale < this.heightScale) {
          table.style.transform = `scale(${this.widthScale})`;
          this.horizontalAdjusted = true;
        } else {
          table.style.transform = `scale(${this.heightScale})`;

          tableWrapper.style.width = tableWrapper.clientWidth / this.heightScale + "px";
          this.horizontalAdjusted = false;
        }
        this.adjustText = "Normal Size";
      }
    },
    addRow() {
      let id = 1;
      let tasksLength = this.deliverables.length;
      if (tasksLength !== 0) {
        id = this.deliverables[tasksLength - 1].id + 1;
      }
      const row = {
        id: id,
        description: "",
        responsible: "",
        weight: 1,
        fulfillment: 0,
        showEditDialog: false,
      };

      this.steps.forEach((step) => {
        let identity = step.identity;
        row[identity] = "open";
      });

      this.deliverables.push(row);
      this.$store.dispatch("setDeliverables", this.deliverables);
    },
    async loadExcel() {
      if (this.file) {
        //TODO for later steps make another constructor for laod excel
        const excelOperator = new ExcelOperator(
          this.deliverables,
          this.steps,
          this.responsibles,
          this.stepsFulfillment,
          this.totalFulfillment
        );

        const passObject = {
          file: this.file,
          deliverables: this.deliverables,
          headers: this.headers,
          steps: this.steps,
          responsibles: this.responsibles,
          stepsFulfillment: this.stepsFulfillment,
          totalFulfillment: this.totalFulfillment,
        };

        let returnedProps = await excelOperator.loadExcel(passObject);

        util.recalculateAllFulfillments(returnedProps.deliverables, returnedProps.steps);

        this.$store.dispatch("setDeliverables", returnedProps.deliverables);
        this.$store.dispatch("setSteps", returnedProps.steps);
        this.$store.dispatch("setResponsibles", returnedProps.responsibles);
        this.$store.dispatch("setHeaders", returnedProps.headers);
        this.$store.dispatch("setRowCounterToZero", 0);
      }
    },
    toggleWeight() {
      this.$emit("handleShowWeight");
    },
    undoDelete() {
      this.$emit("handleUndelete");
    },
  },
};
</script>

<style lang="less" scoped>
.eva-navigation__profile-img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(62%) saturate(3136%) hue-rotate(210deg) brightness(96%)
    contrast(97%);
  width: 2rem;
  margin-bottom: 0.5rem;
}

.btn {
  &__login,
  &__logout {
    margin-bottom: 5px;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 500%;
    padding: 5px 10px;
  }

  &__login {
    border-color: green;
    color: green;
  }

  &__logout {
    border-color: red;
    color: red;
  }
}
</style>
