var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"containerRef",staticClass:"container-width"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center"},[_c('v-col',{staticClass:"overflow-c",attrs:{"cols":"12"}},[_c('div',{ref:"tableRef"},[_c('v-data-table',{staticClass:"elevation-1 zoom custom-data-table ",attrs:{"headers":_vm.filteredHeaders,"items":_vm.deliverables,"hide-default-header":"","hide-default-footer":"","sort-by":_vm.sortBy,"disable-pagination":"","sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',{ref:"headerRef"},_vm._l((headers),function(header){return _c('th',{key:header.value,staticStyle:{"border":"solid 0.5px","padding":"0px 0px"}},[_c('span'),(header.value.includes('step'))?_c('span',{staticStyle:{"height":"100%"}},[_c('linear-progress',{attrs:{"color":"green","height":_vm.thHeight,"value":_vm.stepsFulfillment[header.value]}},[_vm._v(" "+_vm._s(_vm.stepsFulfillment[header.value] ? Math.round(_vm.stepsFulfillment[header.value]) : 0)+"% ")])],1):(header.value === 'fulfillment')?_c('span',[_c('linear-progress',{attrs:{"value":_vm.totalFulfillment !== null && !isNaN(_vm.totalFulfillment) ? _vm.totalFulfillment : 0,"color":"green","height":_vm.thHeight,"reactive":"reactive","striped":"striped"}},[_vm._v(" "+_vm._s(_vm.totalFulfillment !== null && !isNaN(_vm.totalFulfillment) ? _vm.totalFulfillment + "%" : "n.rel.")+" ")])],1):_c('div',{staticStyle:{"color":"white"},on:{"click":function($event){return _vm.toggleWidth()}}},[_vm._v(" ' ")])])}),0),_c('tr',{staticClass:"secondTopRow"},_vm._l((headers),function(header,idx){return _c('th',{key:idx,staticClass:"sortable",class:{
                      active: _vm.sortBy === header.value,
                      asc: _vm.sortDesc === false,
                    },attrs:{"id":"tableHeader","role":"columnheader","scope":"col","aria-label":((header.text) + ": Sorted ascending. Activate to sort descending."),"aria-sort":"ascending"}},[_c('edit-dialog-table-header',{attrs:{"header":header},on:{"handleChangeHeader":_vm.changeHeader,"handleInputChange":_vm.inputChanged,"handleHeaderTextFileOpend":_vm.headerTextFieldOpen}}),(header.headerEditable)?_c('span',{attrs:{"id":"deleteIcon"},on:{"click":function($event){return _vm.conformMessage('', idx, 'column')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_c('span',[_c('span',{on:{"click":function($event){return _vm.sortColumn(header.value)}}},[_c('span',[_vm._v(_vm._s(header.text)+" ")]),_c('span',[_c('i',{staticClass:"v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light",staticStyle:{"font-size":"18px","transition":"transform 0.25s linear"},style:({
                              transform: ("rotate(" + (_vm.rotateValues[header.value]) + "deg)"),
                            }),attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.rotateValues[header.value] += _vm.rotateValues[header.value] === 0 ? -180 : 180}}})])])])],1)}),0)])]}},{key:"body",fn:function(ref){
                            var items = ref.items;
                            var headers = ref.headers;
return [_c('tbody',[_vm._l((items),function(item,idx){return [_c('tr',{ref:"rowRef",refInFor:true},_vm._l((headers),function(header,key){return _c('td',{key:("inner_" + key),class:{ 'expanded-td': _vm.expandedWidth },style:(_vm.cellBackgroundStyle(item[header.value])),attrs:{"title":_vm.expandedWidth ? _vm.getTitle(item, header) : ''},on:{"mouseover":function($event){item.showEditDialog = true},"mouseleave":function($event){return _vm.mouseLeave(item)}}},[(header.editable)?_c('span',{class:{
                          'left-justified': header.value === 'description' || header.value.includes('responsible'),
                        }},[(!item.showEditDialog && !item.isChild)?_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")]):_c('span',[(header.value.includes('step'))?[(item.isChild)?_c('span',[_vm._v(" "+_vm._s(_vm.subProjectStepsFullFilment[item.id] ? parseInt(Math.round(_vm.subProjectStepsFullFilment[item.id][header.value])) : 0)+"% ")]):_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleSelectChange(item, header.value)}}},[_vm._v(" "+_vm._s(item[header.value])+" ")])]:[_c('edit-dialog-table-body',{attrs:{"item":item,"header":header},on:{"handleChangeCell":_vm.changeCell,"handleClose1":_vm.close1,"handleInputChanged":_vm.inputChanged,"handleOpen":_vm.open}})]],2)]):_c('span',[(header.value === 'fulfillment')?_c('span',[_c('linear-progress',{attrs:{"value":typeof item[header.value] === 'number' ? item[header.value] : 0,"color":"green","height":_vm.tdHeight,"reactive":"reactive","striped":"striped"}},[_vm._v(" "+_vm._s(typeof item[header.value] === "number" ? Math.round(item[header.value]) + "%" : "n.rel.")+" ")])],1):_c('div',{class:{
                            'deliverable--number': header.value === 'id',
                            'selected-row': _vm.isSelected(item.id),
                          },on:{"click":function($event){return _vm.handleClick($event, idx, item.id)}}},[_vm._v(" "+_vm._s(item[header.value])+" ")])])])}),0),(item.children && item.children.length > 0)?_vm._l((item.children),function(child,childIdx){return _c('tr',{key:("child_" + idx + "_" + childIdx)},_vm._l((headers),function(header,index){return _c('td',{key:("inner_child_" + index),class:{ 'expanded-td': _vm.expandedWidth },style:(_vm.cellBackgroundStyle(child[header.value])),attrs:{"title":_vm.expandedWidth ? _vm.getTitle(child, header) : ''},on:{"mouseover":function($event){child.showEditDialog = true},"mouseleave":function($event){return _vm.mouseLeave(child)}}},[(header.editable)?_c('span',{class:{
                            'left-justified': header.value === 'description' || header.value.includes('responsible'),
                          }},[(!child.showEditDialog)?_c('span',[_vm._v(_vm._s(child[header.value]))]):_c('span',[(header.value.includes('step'))?[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleSelectChange(child, header.value)}}},[_vm._v(" "+_vm._s(child[header.value])+" ")])]:[_c('edit-dialog-table-body',{attrs:{"item":child,"header":header},on:{"handleChangeCell":_vm.changeCell,"handleClose1":_vm.close1,"handleInputChanged":_vm.inputChanged,"handleOpen":_vm.open}})]],2)]):_c('span',[(header.value === 'fulfillment')?_c('span',[_c('linear-progress',{attrs:{"value":typeof child[header.value] === 'number' ? child[header.value] : 0,"color":"green","height":_vm.tdHeight,"reactive":"reactive","striped":"striped"}},[_vm._v(" "+_vm._s(typeof child[header.value] === "number" ? Math.round(child[header.value]) + "%" : "n.rel.")+" ")])],1):_c('div',{class:{
                              'deliverable--number': header.value == 'id',
                              'selected-row': _vm.isSelected(child.id),
                            },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClick($event, childIdx, child.id, idx)}}},[_vm._v(" "+_vm._s(child[header.value])+" ")])])])}),0)}):_vm._e()]}),(_vm.toolboxVisible)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closetoolbox),expression:"closetoolbox"}],staticClass:"toolbox",style:({ top: _vm.selectedRowTop + 'px' })},[_c('v-icon',{attrs:{"small":""},on:{"click":_vm.deleteRows}},[_vm._v("mdi-delete")]),_c('v-icon',{attrs:{"small":""},on:{"click":_vm.createNewRowFromSelected}},[_vm._v("mdi-arrow-right")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.ungroupSelectedChildren.apply(null, arguments)}}},[_vm._v("mdi-arrow-left")])],1):_vm._e()],2)]}},{key:"top",fn:function(){return [_c('toolbar',{attrs:{"showWeight":_vm.showWeight},on:{"handleShowWeight":_vm.toggleWeight,"handleUndelete":_vm.undoDelete}})]},proxy:true}])})],1)])],1)],1),(_vm.showConformMessage)?_c('div',[_c('ConfirmMessage',{attrs:{"message":_vm.message,"pressedType":_vm.pressedType},on:{"deleteRow":_vm.deleteRow,"deleteStep":function($event){return _vm.deleteColumn('step')},"deleteResponsible":function($event){return _vm.deleteColumn('responsible')},"dontDelete":_vm.toggleShowConformMessage}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }