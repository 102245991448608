module.exports = {
  appName: "Kyra",
  mybusiness: {
    title: "My Business",
    subscription: {
      title: "Subscription",
      rates_title: "Rate",
      usage_title: "Usage",
      quota_title: "Quota",
      note1: "Rates are USD/Month per Team/User/Design/K-Requests Count",
      note2: "Totals are monthly and do not include Tax, AWS or other fees (check AWS Billing for the actual cost)",
      teams: "Teams",
      users: "Users",
      designs: "Designs",
      k_requests: "1K Reqs",
      total: "Total",
      "close-btn": "Close",
      "update-btn": "Update",
      load: {
        failed: {
          summary: "Failed",
          detail: "Failed to load subscription details, please try again.",
        },
      },
      quota: {
        "unsubscribed-team-create": {
          summary: "We are happy you want to do more! Great!",
          detail: "Subscribe to create a new team",
        },
        "private-quota-reached": {
          summary: "We are happy you want to do more! Great!",
          detail: "Subscribe to do more designs.",
        },
        "team-quota-reached": {
          summary: "We are happy you want to do more! Great!",
          detail: "It's your team owner ({emailAddress}) who can extend the limits. Click here to send an email",
        },
      },
    },
  },
  subscribead: {
    title: "subscribe",
    note: "we use aws-marketplace for managing and billing subscription. for doing so, {needAwsMarketplaceAccount}. {viewKyraOnAwsMarketplace}",
    needAwsMarketplaceAccount: "you need to have an aws-marketplace account",
    viewKyraOnAwsMarketplace: "click here to view kyra.blue on aws-marketplace",
    closeBtn: "Close",
    subscribeBtn: "Subscribe",
    hideThisLink: "don't want to see this again, hide it",
    awsmpAppLink: {
      dev: "https://aws.amazon.com/marketplace/pp/prodview-ocnt6x4vmxaoi",
      prod: "https://aws.amazon.com/marketplace/pp/prodview-q54ohpbnmp6qw",
    },
  },
  setting: {
    title: "Setting",
    modObjType: {
      header: "Manage Modules/Object-types/Objects options",
      downloadHeader: "Download options as a CSV file",
      downloadBtn: "Download CSV",
      uploadHeader: "Upload and replace options from a CSV file",
      uploadPlaceholder: "Select the CSV file",
      uploadBtn: "Upload CSV",
      uploadNote: "Use the downloaded file from {downloadHeader} as a template.",
      uploadNote2: "The uploading CSV file MUST match the template structure.",
      resetHeader: "Reset options to Defaults",
      resetBtn: "Reset to Defaults",
      resetNote: "BE CAREFUL, IRREVERSIBLE!",
      startOpList: " Start with empty options list",
      resetOpList: "  Reset to empty options list",
      uploadSuccess: {
        summary: "Success",
        detail: "Options CSV file uploaded.",
      },
      uploadFailed: {
        summary: "Failed",
        detail: "Failed to read CSV file content, please try again.",
      },
      resetSuccess: {
        summary: "Success",
        detail: "Options were reset to defaults.",
      },
    },
    emptyModule: {
      resetSuccess: {
        summary: "Success",
        detail: "Options were reseted to Empty List",
      },
    },
    closeBtn: "Close",
    menu: {
      general: "General",
      advanced: "Advanced",
      accessibility: "Accessibility",
      quickAccsToolbar: "Quick Access Toolbar",
    },
  },
  toolbox: {
    modulesHeader: "Modules",
    objectTypesHeader: "Object Types",
    objectsHeader: "Objects",
    modules: "Modules",
    objectTypes: "Object Types",
    objects: "Objects",
    list: {
      failed: {
        summary: "Failed",
        detail: "Failed to load {category} options, please try again.",
      },
    },
    uploadModObjType: {
      failed: {
        summary: "Failed",
        detail: "Failed to upload modules/object-types/objects csv file, please try again.",
      },
    },
    resetModObjTypeToDefaults: {
      failed: {
        summary: "Failed",
        detail: "Failed to reset modules/object-types/objects options to default, please try again.",
      },
    },
    reset: {
      failed: {
        summary: "Failed",
        detail: "Failed to reset {category} options, please try again.",
      },
    },
  },
  designsList: {
    createPosting: "Create Posting",
    // deletionSuccess: {
    //   summary: "Success",
    //   detail: "Process design deleted successfully.",
    // },
    // deletionFailed: {
    //   summary: "Failed",
    //   detail: "Failed to delete process design.",
    // },
  },
  signin: {
    header: "Welcome back!",
  },
  signout: {
    failed: {
      summary: "Failed",
      detail: "Failed to sign out, please reload the page and try again.",
    },
  },
  profile: {
    emailNotVerified: "not verified",
    confirmBtn: "Confirm",
    loadInfo: {
      failed: {
        summary: "Failed",
        detail: "Failed to load your profile info, please reload the page.",
      },
    },
    changePassword: {
      header: "Change Password",
      currentLbl: "Enter your current password",
      currentHint: "At least 8 characters",
      newLbl: "Enter your new password",
      newHint: "At least 8 characters",
      submitBtn: "Submit",
      success: {
        summary: "Success",
        detail: "Your password updated successfully.",
      },
      failed: {
        summary: "Failed",
        detail: "Failed to change your password, please check your inputs, and try again.",
      },
    },
    closeBtn: "Close",
    verifyEmail: {
      info: {
        summary: "Info",
        detail: "A 6-digit code has been sent to your email.",
      },
      failed: {
        summary: "Failed",
        detail: "Failed to send verification code, please try again.",
      },
      confirm: {
        success: {
          summary: "Success",
          detail: "Failed to verify the code, please check the code, and try again.",
        },
      },
    },
  },
  search: {
    placeholder: "Search ...",
    inProcessDesigns: "in Process Designs",
    inPlayers: "in Players",
  },
  processDesigner: {
    fixValidationIssues: "Please fix following issue(s):",
    titlePlaceholder: "Type design title",
    activitiesHeader: "Activity",
    processFlowHeader: "Process Flow",
    columnGroup: "C/G",
    rowGroup: "R/G",
    allocationsHeader: "Allocation",
    objectTypeHeader: "Object Type",
    objectHeader: "Player",
    trackTypeHeader: "Track Type",
    glAccountHeader: "G/L Account",
    activityPlaceholder: "Activity {i}",
    trackTypePlaceholder: "Track Type {i}",
    noObject: {
      text: "{addObject} by dragging one from the left sidebar",
      addObject: "Add an object",
    },
    noDesign: {
      text: "{selectDesign} from the list, or {createOne}",
      selectDesign: "Select a design",
      createOne: "create one",
    },
    list: {
      failed: {
        summary: "Failed",
        detail: "Failed to load process designs, please reload the page.",
      },
    },
    create: {
      failed: {
        summary: "Failed",
        detail: "Failed to create a new process design, please try again.",
      },
    },
    addPlayer: {
      failed: {
        summary: "Failed",
        detail: "Failed to create a new Player to the Defaults folder, please select another place.",
      },
    },
    load: {
      failed: {
        summary: "Failed",
        detail: "Failed to load the process design, please try again.",
      },
    },
    save: {
      failed: {
        summary: "Failed",
        detail: "Failed to save process design changes, please try again.",
      },
    },
    delete: {
      failed: {
        summary: "Failed",
        detail: "Failed to delete the process design, please try again.",
      },
    },
    duplicatePlayer: {
      failed: {
        summary: "Player already exists",
        detail: "Failed to add the player, please try another one.",
      },
    },
    otherProcPlayer: {
      failed: {
        summary: "Other process players not allowed.",
        detail: "The player is neither part of the default players nor the process players.",
      },
    },
    arrow: {
      maxCount: "Sorry! Can not have more than 3 arrows for an activity.",
      noSelectedArrow: "Select an arrow to delete.",
      notSameArrow: "Select arrow from the current row.",
    },
  },
  select: {
    modules: {
      duplicate: {
        summary: "Duplicate Module Abbreviation",
        detail: "A module with this abbreviation already exists please try another",
      },
    },
    objectTypes: {
      duplicate: {
        summary: "Duplicate Object Type Title",
        detail: "An object type with this title already exists please try another",
      },
      moduleNotSelected: {
        summary: "Module Not Selected",
        detail: "Please Select a Module",
      },
    },
    objects: {
      duplicate: {
        summary: "Duplicate Object Title",
        detail: "An object with this title already exists please try another",
      },
      moduleNotSelected: {
        summary: "Module or Object Type Not Selected",
        detail: "Please select a module and object type",
      },
    },
  },
  processFlow: {
    zoomLevel: "{value}",
    message: {
      summary: "",
      detail: "To become beta tester of process flow, contact kyra support kyra@kern.ag",
    },
    contact: "kyra@kern.ag",
  },
  teams: {
    createTeam: "Create Team ({teamsFilter})",
    addMember: "Add Member ({membersFilter})",
    myPrivateDesigns: "My Private Designs",
    list: {
      failed: {
        summary: "Failed",
        detail: "Failed to load teams, please reload the page.",
      },
    },
    create: {
      failed: {
        summary: "Failed",
        detail: "Failed to create a new team, please try again.",
      },
    },
    update: {
      failed: {
        summary: "Failed",
        detail: "Failed to update the team, please try again.",
      },
    },
    delete: {
      failed: {
        summary: "Failed",
        detail: "Failed to delete the team, please try again.",
      },
    },
  },
  names: {
    design: {
      1: "Ship Painting",
      2: "Food Knitting",
      3: "Plant Ordering",
      4: "House Checking",
      5: "Health Renting",
      6: "Product Drawing",
      7: "Company Repairing",
      8: "Tie Replacing",
      9: "Car Sketching",
      10: "Book Folding",
      11: "Finance Jumping",
      12: "Money Tasting",
      13: "Hotel Sprinting",
      14: "Target Booking",
      15: "Mail Riding",
      16: "Leader Diving",
      17: "Vacation Growing",
    },
    activity: {
      1: "Jumping",
      2: "Sailing",
      3: "Glueing",
      4: "Sticking",
      5: "Riding",
      6: "Cooking",
      7: "Baking",
      8: "Seasoning",
      9: "Stirring",
      10: "Snorkeling",
      11: "Climbing",
      12: "Closing",
      13: "Opening",
      14: "Planning",
      15: "Discussing",
      16: "Driving",
      17: "Raiding",
      18: "Decorating",
    },
  },
  homePage: {
    processDesigns: "Process Designs",
    contact: "Contact",
    signIn: "Sign In",
    header: {
      part1: "Your Digital",
      part2: "Process Manager",
    },
    tryBtn: "Test for free",
    message: [
      "Simple. Dynamic. Understandable. This is how process design works with Kyra. Visualize your workflows clearlt and professionally. Get started immediately",
      "With the Kyra process tool, you can easily map work processes and visualize them clearly and professionally. Get started right away",
      "Introducing Kyra: Your Key to Streamlined Workflows! With our powerful process mapping tool, you can easily visualize and optimize your work processes. Start now and experience the clarity and professionalism Kyra brings.",
      "Unlock the Power of Kyra: Simplify, Streamline, Succeed! Our dynamic process design tool empowers you to create workflows that are simple, understandable, and efficient. Start visualizing your processes clearly and professionally with Kyra. Begin today.",
      "Efficiency at Your Fingertips with Kyra! Seamlessly map and optimize your work processes with our intuitive process tool. Say goodbye to complexity and hello to clarity as you visualize your workflows with Kyra. Start now and experience the simplicity firsthand.",
    ],
    disclosure: "Legal Disclosure",
  },
  impressumPage: {
    title: "Legal Disclosure",
    signIn: "Sign In",
    contact: "Contact",
    address: "Address",
    moreInfo: "More Information",
    phone: "Phone",
    chairman: "Executive Board",
    register: "Commercial Register",
    taxId: "VAT ID",
    disclosure: "Legal Disclosure",
    disclaimer: "Disclaimer",
    disclaimerMessage:
      "Kern AG would like to point out that linked pages are not originated from it. Insofar as these have illegal or incorrect content, the Kern AG expressly dissociates itself from them. The Kern AG aims to provide only legal and error-free facts and information and has no influence on the design and content of linked pages. These also do not constitute an extension or supplementation of its own information. The Kern AG is not able to regularly monitor all changes on the linked pages. For this reason, any responsibility and liability for the content of all such linked information is declined. For illegal, incorrect or incomplete content, and especially for damages arising from the use of such information, the respective provider of the page to which reference is made is solely liable.",
  },
  goTo: {
    select: {
      title: "GoTo/Select",
      hint: "Enter one cell coordinate to go there or two to select a range",
    },
    shortcuts: {
      title: "Shortcuts",
      teams: "Teams",
      players: "Players",
      player: "Player",
      processes: "Processes",
      settings: "Settings",
      tools: "Tools",
      styles: "Styles",
    },
    favorites: {
      title: "Favorites",
      noFavorites: "No Favorite Process",
    },
    range: {
      row: "Row",
      col: "Col",
    },
    standard: {
      title: "Standard",
    },
  },
  fill: {
    down: "Down",
    up: "Up",
    right: "Right",
    left: "left",
  },
  actions: {
    ok: "OK",
    confirm: "Confirm",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    update: "Update",
  },
  sidebar: {
    processes: "Processes",
    players: "Players",
    teams: "Teams",
    styles: {
      title: "Styles",
      menu: {
        styles: "Styles",
        templates: "Templates",
        advanced: "Advanced",
      },
    },
    newProcess: "New Process",
    newPostingProcess: "New Posting Process",
    newFolder: "New Folder",
    newGrid: "New Grid",
    newPlayer: "New Player",
    newTeam: "New Team",
    settings: "Settings",
    delete: {
      player: {
        title: "Delete Player",
        description: "Are you sure you want to delete this Player?",
      },
      team: {
        title: "Delete Player",
        description: "Are you sure you want to delete this Player?",
      },
      process: {
        title: "Delete Process",
        description: "Are you sure you want to delete this Process?",
      },
      view: {
        title: "Delete View",
        description: "Are you sure you want to delete this View?",
      },
      sheet: {
        title: "Delete Sheet",
        description: "Are you sure you want to delete this Sheet?",
      },
    },
    trash: {
      emptyMessage: "Trash is Empty",
      selectAll: "Select All",
    },
    sheets: {
      title: "Sheets",
    },
  },
  contextMenu: {
    activity: {
      addRowGro: "Add Row Group",
      addColGro: "Add Column Group",
      addColumn: "Add Column",
      addRow: "Add Row",
      removeRow: "Remove Row",
      addArrow: "Add Arrow",
      removeCol: "Remove Column",
      moveLeft: "Move Left Ctrl+Left",
      moveRight: "Move Right Ctrl+Right",
      moveUp: "Move Up Ctrl+Up",
      moveDown: "Move Down Ctrl+Down",
      deleteArrow: "Delete selected arrow",
    },
    open: "Open",
    new: "New",
    edit: "Edit",
    rename: "Rename",
    expandCollapse: "Expand/Collapse",
    move: "Move",
    moveUp: "Move Up",
    moveDown: "Move Down",
    moveOneLevelUp: "Move One Level Up",
    moveOneLevelDown: "Move One Level Down",
    delete: "Delete",
    cut: "Cut",
    copy: "Copy",
    paste: "Paste",
    apply: "Apply",
    modify: "Modify",
    duplicate: "Duplicate",
    normalProcess: "Normal Process",
    postingProcess: "Posting Process",
    grid: "Grid",
    folder: "Folder",
    addPlayer: "Add Player",
    newMember: "New Member",
    sendMail: "Send Mail",
    changeRole: "Change Role",
    addMember: "Add A Member",
    styles: "Styles",
    update: "Update",
    Email: "E-mail",
  },
  views: {
    navigationCorner: "Navigation Corner",
    dataView: "Data View",
    styleCorner: "Style Corner",
    title: "Title",
    addNewView: "Add A New View",
    addView: "Add View",
    editView: "Edit View",
    icons: {
      icon: "Icon",
      bag: "Bag",
      home: "Home",
      chart: "Chart",
      chair: "Chair",
      ruler: "Ruler",
      document: "Document",
      dollar: "Dollar",
      euro: "Euro",
      filter: "Filter",
      airPlane: "Airplane",
      car: "Car",
      toolBox: "ToolBox",
      flag: "Flag",
    },
  },
  processDesign: {
    accessToolbar: {
      moreCommands: {
        title: "Customize the Quick Access Toolbar",
        source: "Source",
        target: "Target",
      },
    },
  },
  404: {
    snakes: "404 Snakes !",
    notFound: "Page not found",
    backToDes: " Back to Designs",
  },
  title: {
    undo: "Undo Changes",
    redo: "Redo Changes",
    favoriteProcess: "Set Favorite Process",
    setView: "Select View",
    setNavigationView: "Select Mode",
    commentTitle: "Add or Edit Comment",
    cut: "Cut",
    copy: "Copy",
    goto: "Go to",
    fill: "Fill",
    showCustomStyle: "Show Custom Styles",
    deleteComment: "Delete Comment",
    screenshot: "take screenshot (Ctrl + P)",
    mergeCell: "Merge Cells",
    expandCollapseWithShortcut: "Expand or Collapse (Crtl + Shift + F11)",
    paste: "Paste",
    basicFormatting: "Basic Formatting",
    processFlow: "Process Flow",
  },
};
