// src/aws-exports.js

const awsconfig = {
  Auth: {
    region: "eu-north-1", // e.g., 'us-east-1'
    userPoolId: "eu-north-1_h94Qsmj7v", // e.g., 'us-east-1_XXXXXX'
    userPoolWebClientId: "21tfo5798hvkfgl8dgi5cslad4", // e.g., '1h57kf5cpq17m0eml12EXAMPLE'
    oauth: {
      domain: "https://eva.auth.eu-north-1.amazoncognito.com",
      scope: ["openid", "email", "phone"],
      redirectSignIn: "http://localhost:8080/",
      redirectSignOut: "http://localhost:8080/",
      responseType: "token", // 'code' for Authorization code grant
    },
  },
};

export default awsconfig;
