<template>
  <div ref="containerRef" class="container-width">
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12" class="overflow-c">
          <div ref="tableRef">
            <v-data-table
              :headers="filteredHeaders"
              :items="deliverables"
              hide-default-header
              hide-default-footer
              :sort-by.sync="sortBy"
              disable-pagination
              :sort-desc.sync="sortDesc"
              class="elevation-1 zoom custom-data-table "
            >
              <template v-slot:header="{ props: { headers } }">
                <thead class="v-data-table-header">
                  <!-- Toppest Row -->
                  <tr ref="headerRef">
                    <th
                      style="border: solid 0.5px; padding: 0px 0px;"
                      v-for="header in headers"
                      v-bind:key="header.value"
                    >
                      <span></span>
                      <span style="height: 100%" v-if="header.value.includes('step')">
                        <linear-progress color="green" :height="thHeight" :value="stepsFulfillment[header.value]">
                          {{ stepsFulfillment[header.value] ? Math.round(stepsFulfillment[header.value]) : 0 }}%
                        </linear-progress>
                      </span>
                      <span v-else-if="header.value === 'fulfillment'">
                        <linear-progress
                          :value="totalFulfillment !== null && !isNaN(totalFulfillment) ? totalFulfillment : 0"
                          color="green"
                          :height="thHeight"
                          reactive="reactive"
                          striped="striped"
                        >
                          {{
                            totalFulfillment !== null && !isNaN(totalFulfillment) ? totalFulfillment + "%" : "n.rel."
                          }}
                        </linear-progress>
                      </span>
                      <div v-else style="color: white;" @click="toggleWidth()">
                        '
                      </div>
                    </th>
                  </tr>

                  <!-- Second toppest Row -->
                  <tr class="secondTopRow">
                    <!-- & -->
                    <th
                      id="tableHeader"
                      v-for="(header, idx) in headers"
                      :key="idx"
                      role="columnheader"
                      scope="col"
                      :aria-label="`${header.text}: Sorted ascending. Activate to sort descending.`"
                      aria-sort="ascending"
                      class="sortable"
                      :class="{
                        active: sortBy === header.value,
                        asc: sortDesc === false,
                      }"
                    >
                      <!-- <edit-dialog > </edit-dialog> -->
                      <edit-dialog-table-header
                        @handleChangeHeader="changeHeader"
                        @handleInputChange="inputChanged"
                        @handleHeaderTextFileOpend="headerTextFieldOpen"
                        :header="header"
                      ></edit-dialog-table-header>
                      <!-- <span> -->
                      <span id="deleteIcon" v-if="header.headerEditable" @click="conformMessage('', idx, 'column')">
                        <v-icon small>mdi-delete</v-icon>
                      </span>

                      <!-- non step columns -->
                      <span v-else>
                        <span @click="sortColumn(header.value)">
                          <!-- non step columns title -->
                          <span>{{ header.text }} </span>
                          <span>
                            <!-- The arrow -->
                            <i
                              :style="{
                                transform: `rotate(${rotateValues[header.value]}deg)`,
                              }"
                              @click="rotateValues[header.value] += rotateValues[header.value] === 0 ? -180 : 180"
                              aria-hidden="true"
                              class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light"
                              style="font-size: 18px; transition: transform 0.25s linear;"
                            ></i>
                          </span>
                        </span>
                      </span>
                      <!-- </span> -->
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:body="{ items, headers }">
                <tbody>
                  <template v-for="(item, idx) in items">
                    <tr ref="rowRef">
                      <td
                        @mouseover="item.showEditDialog = true"
                        @mouseleave="mouseLeave(item)"
                        :title="expandedWidth ? getTitle(item, header) : ''"
                        v-for="(header, key) in headers"
                        :key="`inner_${key}`"
                        :style="cellBackgroundStyle(item[header.value])"
                        :class="{ 'expanded-td': expandedWidth }"
                      >
                        <!-- header editable -->
                        <span
                          v-if="header.editable"
                          :class="{
                            'left-justified': header.value === 'description' || header.value.includes('responsible'),
                          }"
                        >
                          <span v-if="!item.showEditDialog && !item.isChild">
                            {{ item[header.value] }}
                          </span>

                          <span v-else>
                            <template v-if="header.value.includes('step')">
                              <!-- Render the value without v-edit-dialog for steps cell -->
                              <span v-if="item.isChild">
                                {{
                                  subProjectStepsFullFilment[item.id]
                                    ? parseInt(Math.round(subProjectStepsFullFilment[item.id][header.value]))
                                    : 0
                                }}%
                              </span>

                              <span v-else @click="handleSelectChange(item, header.value)" style="cursor: pointer;">
                                {{ item[header.value] }}
                              </span>
                            </template>
                            <template v-else>
                              <edit-dialog-table-body
                                @handleChangeCell="changeCell"
                                @handleClose1="close1"
                                @handleInputChanged="inputChanged"
                                @handleOpen="open"
                                :item="item"
                                :header="header"
                              >
                              </edit-dialog-table-body>
                            </template>
                          </span>
                        </span>

                        <!-- header not editable -->
                        <span v-else>
                          <span v-if="header.value === 'fulfillment'">
                            <linear-progress
                              :value="typeof item[header.value] === 'number' ? item[header.value] : 0"
                              color="green"
                              :height="tdHeight"
                              reactive="reactive"
                              striped="striped"
                            >
                              {{
                                typeof item[header.value] === "number" ? Math.round(item[header.value]) + "%" : "n.rel."
                              }}
                            </linear-progress>
                          </span>

                          <!-- The ID  -->
                          <div
                            v-else
                            @click="handleClick($event, idx, item.id)"
                            :class="{
                              'deliverable--number': header.value === 'id',
                              'selected-row': isSelected(item.id),
                            }"
                          >
                            <!-- <span v-if="isRangeSelected" :class="isSelected(item.id)" class="isChecked"> </span> -->
                            {{ item[header.value] }}
                          </div>
                        </span>
                      </td>
                    </tr>

                    <!-- Check if the current row has children -->
                    <template ref="rowRef" v-if="item.children && item.children.length > 0">
                      <!-- Nested loop to render children rows -->
                      <tr v-for="(child, childIdx) in item.children" :key="`child_${idx}_${childIdx}`">
                        <td
                          @mouseover="child.showEditDialog = true"
                          @mouseleave="mouseLeave(child)"
                          :title="expandedWidth ? getTitle(child, header) : ''"
                          v-for="(header, index) in headers"
                          :key="`inner_child_${index}`"
                          :style="cellBackgroundStyle(child[header.value])"
                          :class="{ 'expanded-td': expandedWidth }"
                        >
                          <!-- header editable -->
                          <span
                            v-if="header.editable"
                            :class="{
                              'left-justified': header.value === 'description' || header.value.includes('responsible'),
                            }"
                          >
                            <span v-if="!child.showEditDialog">{{ child[header.value] }}</span>
                            <span v-else>
                              <template v-if="header.value.includes('step')">
                                <!-- Render the value without v-edit-dialog for steps cell -->
                                <span @click="handleSelectChange(child, header.value)" style="cursor: pointer;">
                                  {{ child[header.value] }}
                                </span>
                              </template>
                              <template v-else>
                                <edit-dialog-table-body
                                  @handleChangeCell="changeCell"
                                  @handleClose1="close1"
                                  @handleInputChanged="inputChanged"
                                  @handleOpen="open"
                                  :item="child"
                                  :header="header"
                                >
                                </edit-dialog-table-body>
                              </template>
                            </span>
                          </span>

                          <!-- header not editable -->
                          <span v-else>
                            <span v-if="header.value === 'fulfillment'">
                              <linear-progress
                                :value="typeof child[header.value] === 'number' ? child[header.value] : 0"
                                color="green"
                                :height="tdHeight"
                                reactive="reactive"
                                striped="striped"
                              >
                                {{
                                  typeof child[header.value] === "number"
                                    ? Math.round(child[header.value]) + "%"
                                    : "n.rel."
                                }}
                              </linear-progress>
                            </span>

                            <!-- The ID  -->
                            <div
                              v-else
                              @click="handleClick($event, childIdx, child.id, idx)"
                              :class="{
                                'deliverable--number': header.value == 'id',
                                'selected-row': isSelected(child.id),
                              }"
                              style="cursor: pointer;"
                            >
                              <!-- <span v-if="isRangeSelected" :class="isSelected(child.id)" class="isChecked"> </span> -->
                              {{ child[header.value] }}
                            </div>
                          </span>
                          <!-- </span> -->
                        </td>
                      </tr>
                    </template>
                  </template>

                  <div
                    class="toolbox"
                    v-if="toolboxVisible"
                    :style="{ top: selectedRowTop + 'px' }"
                    v-click-outside="closetoolbox"
                  >
                    <v-icon small @click="deleteRows">mdi-delete</v-icon>
                    <v-icon small @click="createNewRowFromSelected">mdi-arrow-right</v-icon>
                    <v-icon small @click.stop="ungroupSelectedChildren">mdi-arrow-left</v-icon>
                  </div>
                </tbody>
              </template>
              <template v-slot:top>
                <toolbar @handleShowWeight="toggleWeight" @handleUndelete="undoDelete" :showWeight="showWeight" />
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="showConformMessage">
      <ConfirmMessage
        :message="message"
        :pressedType="pressedType"
        @deleteRow="deleteRow"
        @deleteStep="deleteColumn('step')"
        @deleteResponsible="deleteColumn('responsible')"
        @dontDelete="toggleShowConformMessage"
      />
    </div>
  </div>
</template>

<script>
// import ExcelOperator from "../utils/excel-operator";
import FulfilmentsUtil from "../utils/fulfilments-util";
// import AddColumn from "@/components/AddColumn";
import ConfirmMessage from "@/components/ConfirmMessage";
import { mapState } from "vuex";
import LinearProgress from "./Table/LinearProgress.vue";
// import Editd from "./Table/EditDialog.vue";
import EditDialogTableBody from "./Table/EditDialogTableBody.vue";
import Toolbar from "./Table/Toolbar.vue";
import EditDialogTableHeader from "./Table/EditDialogTableHeader.vue";
import { Auth } from "aws-amplify";

const util = new FulfilmentsUtil();

export default {
  name: "HelloWorld",
  components: {
    ConfirmMessage,
    LinearProgress,
    EditDialogTableHeader,
    EditDialogTableBody,
    Toolbar,
  },
  data: () => ({
    index: -1,
    selectedRowTop: 0,
    selectedRows: [], // Track selected rows by index
    selectedRow: null,
    isRangeSelected: false,
    deletedRows: [],
    deletedRowsStack: [],
    isShiftPressed: false,
    toolboxVisible: false,
    expandedWidth: true,
    isDialogOpen: false,
    showEditDialog: true,
    selectItems: ["open", "wip", "closed", "n.rel."],
    rotateValues: { id: 0, description: 0, weight: 0, fulfillment: 0 },
    sortBy: "id",
    sortDesc: false,
    file: null,
    inputValue: "",
    showWeight: "Show Weight",
    max20chars: (v) => v.length <= 20 || "Maximum 20 character allowed",
    rules: {
      counter: (value) => value.length <= 20 || "Max 20 characters",
      required: (value) => !!value || "Required.",
      positiveNumbers: (value) => value > 0 || "Should be greater than 0",
      alreadyExist: true,
    },

    adjustText: "Fit On Page",
    heightScale: 0,
    widthScale: 0,
    horizontalAdjusted: false,

    thHeight: 16,
    thWidth: 0,
    tdHeight: 16,
    headerEditValue: "",

    defaultItem: {
      id: "",
      description: "",
      oneWeekBefore: "",
      untilDayBefore: "",
      fulfillment: 0,
    },
    stepOffset: 3,
    // Added by MMG
    showDeleteIcon: false,
    columnDeleteIcon: false,
    message: "The Message",
    pressedId: "",
    pressedType: "",
    showConformMessage: false,
    activeChildIndex: null,
  }),
  computed: {
    stepsFulfillment() {
      return this.$store.getters.stepsFulfillment;
    },
    subProjectStepsFullFilment() {
      return this.$store.getters.subProjectStepsFullFilment;
    },
    isSubscribed() {
      return this.$store.getters.getSubID;
    },
    subStatus() {
      return this.$store.getters.getSubStatus;
    },
    isAuthenticated() {
      return this.$store.getters.getAuth == "signed-in" ? true : false;
    },
    ...mapState(["statusTable"]),
    headers() {
      return this.statusTable.headers;
    },
    filteredHeaders() {
      // Add a checkbox header
      return this.statusTable.headers.filter((item) => {
        if (this.showWeight === "Show Weight") {
          return item.value !== "weight";
        } else {
          return true;
        }
      });
    },
    deliverables() {
      return this.statusTable.deliverables;
    },
    responsibles() {
      return this.statusTable.responsibles;
    },
    steps() {
      return this.statusTable.steps;
    },
    totalFulfillment() {
      return Math.round(this.$store.getters.totalFulfillment);
    },
  },
  mounted: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      if (this.$refs.headerRef) this.thHeight = this.$refs.headerRef.clientHeight;
      this.thWidth = this.$refs.headerRef.clientWidth;
      if (this.$refs.rowRef) this.tdHeight = this.$refs.rowRef[0].clientHeight;

      const tableToolbarHeight = document.getElementsByClassName("v-toolbar__content")[0].clientHeight;
      const containerHeight = document.getElementsByClassName("overflow-c")[0].clientHeight;
      const tableWrapper = document.getElementsByClassName("v-data-table__wrapper")[0];
      tableWrapper.style.maxHeight = containerHeight - tableToolbarHeight + "px";
    });
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    // Method to ungroup a child project
    ungroupSelectedChildren() {
      // Create an array to store the indices of children to be ungrouped
      const childrenToUngroup = [];

      // Iterate over the selected rows
      this.selectedRows.forEach((rowId) => {
        const parentIdx = this.findParentIndex(rowId);
        if (parentIdx !== -1) {
          const childIdx = this.findChildIndex(parentIdx, rowId);
          if (childIdx !== -1) {
            childrenToUngroup.push({ parentIdx, childIdx });
          }
        }
      });

      // Sort children to ungroup in descending order to avoid index issues
      childrenToUngroup.sort((a, b) => b.parentIdx - a.parentIdx || b.childIdx - a.childIdx);

      // Ungroup each child
      childrenToUngroup.forEach(({ parentIdx, childIdx }) => {
        this.ungroupChild(parentIdx, childIdx);
      });

      // Clear selection after ungrouping
      this.selectedRows = [];

      // Update the Vuex store if necessary
      this.$store.dispatch("setDeliverables", this.deliverables);

      // Optionally, recalculate fulfillments
      util.recalculateAllFulfillments(this.deliverables, this.steps);
    },

    // Method to ungroup a single child project
    ungroupChild(parentIdx, childIdx) {
      const parent = this.deliverables[parentIdx];
      const child = parent.children[childIdx];

      // Remove the child from the parent's children array
      parent.children.splice(childIdx, 1);

      // Insert the child right after the parent in the root deliverables array
      this.deliverables.splice(parentIdx + 1, 0, child);

      // If the parent has no more children, remove the parent as well
      if (parent.children.length === 0) {
        this.deliverables.splice(parentIdx, 1);
      }

      // Recalculate numbering
      this.recalculateNumbering();
    },

    // Method to find parent index
    findParentIndex(childId) {
      for (let i = 0; i < this.deliverables.length; i++) {
        if (this.deliverables[i].children) {
          for (let j = 0; j < this.deliverables[i].children.length; j++) {
            if (this.deliverables[i].children[j].id === childId) {
              return i;
            }
          }
        }
      }
      return -1;
    },

    // Method to find child index
    findChildIndex(parentIdx, childId) {
      const parent = this.deliverables[parentIdx];
      for (let i = 0; i < parent.children.length; i++) {
        if (parent.children[i].id === childId) {
          return i;
        }
      }
      return -1;
    },

    // Method to recalculate numbering
    recalculateNumbering() {
      this.deliverables.forEach((item, index) => {
        item.id = index + 1;
        if (item.children && item.children.length > 0) {
          item.children.forEach((child, childIndex) => {
            child.id = `${item.id}.${childIndex + 1}`;
          });
        }
      });
    },
    closetoolbox(event) {
      if (!event.target.classList.contains("deliverable--number")) {
        this.toolboxVisible = false;
        this.index = -1;
        this.selectedRows = [];
      }
    },

    include() {
      return [document.querySelector(".deliverable--number")];
    },

    renumberDeliverables(deliverables) {
      return deliverables.map((row, index) => {
        if (!row.isGrouped) {
          return { ...row, id: index + 1 };
        }

        // Renumber the row
        const renumberedRow = { ...row, id: index + 1 };

        // Renumber the children if they exist
        if (renumberedRow.children && renumberedRow.children.length > 0) {
          renumberedRow.children = renumberedRow.children.map((child, childIndex) => {
            return { ...child, id: `${renumberedRow.id}.${childIndex + 1}` };
          });
        }
        return renumberedRow;
      });
    },

    createNewRowFromSelected() {
      if (!this.isAuthenticated) {
        return this.$store.dispatch("addToast", {
          severity: "info",
          life: 3000,
          summary: "Not signed in",
          detail: "You need to sign in to create subproject",
        });
      }
      if (!this.subStatus || this.subStatus === "inactive") {
        return this.$store.dispatch("setDeleteDialog", {
          show: true,
        });
      }
      if (this.selectedRows.length > 0) {
        // Create a new row object with default values
        const newRow = {
          description: "Subproject",
          fulfillment: 0,
          id: Math.min(...this.selectedRows), // Generate a new ID based on the highest selected ID
          responsible: "",
          showEditDialog: false,
          weight: 0,
          isChild: true,
          children: [],
          isGrouped: true,
        };

        // Calculate the total weight for the selected rows
        let totalWeight = 0;

        // Update the selected rows and move them to the children property of the new row
        newRow.children = this.selectedRows.map((id, index) => {
          const selectedRow = this.deliverables.find((deliverable) => deliverable.id === id);
          totalWeight += parseInt(selectedRow.weight);
          return {
            ...selectedRow,
            id: `${newRow.id}.${index + 1}`,
            isGrouped: true,
            parentId: newRow.id,
          };
        });

        // Set the calculated values to the new row
        newRow.weight = totalWeight;

        // Remove the selected rows from the deliverables array
        let updatedDeliverables = this.deliverables.filter(
          (deliverable) => !this.selectedRows.includes(deliverable.id)
        );

        // Calculate percentages and values for each step
        this.calculateStepsFulfillmentForNewRow(newRow);

        // Find the index where the new row should be inserted based on the 'id'
        const insertIndex = updatedDeliverables.findIndex((row) => newRow.id - 1 < row.id);

        // If insertIndex is -1, it means the new row should be added at the end
        const finalIndex = insertIndex !== -1 ? insertIndex : updatedDeliverables.length;

        // Insert the new row at the calculated position
        updatedDeliverables.splice(finalIndex, 0, newRow);

        // Clear selection after creating the new row
        this.selectedRows = [];

        // Hide the toolbox
        this.toolboxVisible = false;

        // renumber deliverables
        updatedDeliverables = this.renumberDeliverables(updatedDeliverables);

        // Update the store
        this.$store.dispatch("setDeliverables", updatedDeliverables);
      }
    },

    calculateStepsFulfillmentForNewRow(row) {
      // Calculate percentages based on total weight and fulfillment
      if (row.weight > 0) {
        let sumAll = 0;
        // let totalWeights = 0;

        this.steps.forEach((step) => {
          let totalWeights = 0;
          let wipCount = 0;
          let closedCount = 0;

          row.children.forEach((item, idx) => {
            // const selectedRow = this.deliverables[idx];
            if (item[step.identity] !== "n.rel.") {
              totalWeights += +item.weight;
            }
            closedCount += item[step.identity] === "closed" ? 1 * item.weight : 0;
            wipCount += item[step.identity] === "wip" ? 0.5 * item.weight : 0;
          });
          // const stepValue = row[step.identity];
          row[step.identity] = ((closedCount + wipCount) / totalWeights) * 100;
        });

        // calculate the totalfulfillment for the selected rows.
        if (this.steps.length > 0) {
          let totalWeights = 0;
          row.children.forEach((item, idx) => {
            // const selectedRow = this.deliverables[idx];
            if (typeof item.fulfillment === "number") {
              totalWeights += +item.weight;
              sumAll += item.fulfillment * item.weight;
            }
          });
          row.fulfillment = sumAll / totalWeights;
        }
      }
    },

    deleteRows() {
      if (this.selectedRows.length > 0) {
        // Store deleted rows temporarily
        const deletedRows = [];

        // Iterate over selected rows and delete them
        this.selectedRows.forEach((id) => {
          const parentIdx = this.deliverables.findIndex((deliverable) => deliverable.id === id);

          if (parentIdx !== -1) {
            const parent = this.deliverables[parentIdx];
            // Check if the item is a parent with children
            if (parent.children && parent.children.length > 0) {
              // Delete the parent and all its children
              deletedRows.push(...parent.children);
              deletedRows.push(parent);
              this.deliverables.splice(parentIdx, 1);
            } else {
              // Delete the parent without children
              deletedRows.push(parent);
              this.deliverables.splice(parentIdx, 1);
            }
          } else {
            // Handle deletion of child rows if necessary
            this.deliverables.forEach((parent, parentIndex) => {
              const childIdx = parent.children ? parent.children.findIndex((child) => child.id === id) : -1;
              if (childIdx !== -1) {
                const deletedChild = parent.children.splice(childIdx, 1)[0];
                deletedRows.push(deletedChild);
                // If no children left, remove the parent
                if (parent.children.length === 0) {
                  this.deliverables.splice(parentIndex, 1);
                  deletedRows.push(parent);
                }
              }
            });
          }
        });

        // Clear selection after deletion
        this.selectedRows = [];

        // Store deleted rows for potential undo
        this.deletedRowsStack.push(deletedRows);

        // Recalculate fulfillments
        util.recalculateAllFulfillments(this.deliverables, this.steps);

        // Update the store
        this.$store.dispatch("setDeliverables", this.deliverables);

        // Update the toolbox visibility
        this.toolboxVisible = false;
        this.index = null;
        // }
      }
    },

    // Function to find the next available ID
    getNextAvailableId(deliverables) {
      let id = 1;
      let tasksLength = deliverables.length;
      if (tasksLength !== 0) {
        id = this.deliverables[tasksLength - 1].id + 1;
      }
      return id;
    },

    undoDelete() {
      if (this.deletedRowsStack.length > 0) {
        // Pop the last set of deleted rows from the stack
        const lastDeletedRows = this.deletedRowsStack.pop();

        // Restore deleted rows
        lastDeletedRows.forEach((row) => {
          if (row.parentId) {
            // If the row is a child, find the parent and restore the child
            const parent = this.deliverables.find((deliverable) => deliverable.id === row.parentId);
            if (parent) {
              parent.children = parent.children || [];
              parent.children.push(row);
              parent.children.sort((a, b) => a.id - b.id);
            }
          } else {
            // If the row is a parent, check if it already exists in the deliverables array
            const existingRow = this.deliverables.find((deliverable) => deliverable.id === row.id);
            if (existingRow) {
              // Assign a new ID if the parent row already exists
              row.id = this.getNextAvailableId(this.deliverables);
            }
            this.deliverables.push(row);
          }
        });

        // Recalculate fulfillments
        const util = new FulfilmentsUtil();
        util.recalculateAllFulfillments(this.deliverables, this.steps);

        // Update the store (assuming you're using Vuex)
        this.$store.dispatch("setDeliverables", this.deliverables);
      }
    },

    handleClick(event, idx, id, parentIdx = null) {
      this.positionToolBox(idx, parentIdx);
      if (event.shiftKey) {
        this.handleRangeSelection(this.index, idx, parentIdx);
        this.index = null;
        this.isRangeSelected = true;
        return;
      } else if (event.ctrlKey) {
        this.selectedRows.push(id);
        this.isRangeSelected = true;
        this.index = null;
      } else {
        this.selectedRows = [id];
        this.index = idx;
        this.isRangeSelected = false;
      }
    },

    isSelected(id) {
      // const item = this.selectedRows.includes(id);
      // return item ? "mdi mdi-check" : "";
      return this.selectedRows.includes(id);
    },

    handleRangeSelection(startIndex, endIndex, parentIndex = null) {
      if (startIndex > endIndex) {
        [startIndex, endIndex] = [endIndex, startIndex];
      }
      // Select the range between the last selected item and the current item
      for (let i = startIndex; i <= endIndex; i++) {
        const itemId = parentIndex ? this.deliverables[parentIndex].children[i]?.id : this.deliverables[i]?.id;
        if (!this.selectedRows.includes(itemId)) {
          this.selectedRows.push(itemId);
        }
      }
    },

    positionToolBox(parentIdx, childIdx) {
      const rowHeight = 20; // Adjust this based on your table row height
      this.selectedRowTop = (childIdx !== null ? childIdx + parentIdx : parentIdx) * rowHeight;
      this.toolboxVisible = true;
    },

    handleSelectChange(item, headerValue) {
      // Assuming steps are defined as an array
      const steps = ["open", "wip", "closed", "n.rel."];

      // Get the current step index
      const currentStepIndex = steps.indexOf(item[headerValue]);

      // Calculate the next step index (cycling back to 0 if at the end)
      const nextStepIndex = (currentStepIndex + 1) % steps.length;

      // Set the new step value
      this.$set(item, headerValue, steps[nextStepIndex]);

      if (!item.isChild) {
        // Recalculate the row
        this.calculateRowFulfillment(item);
      }

      if (item.parentId !== undefined) {
        // The item is a child, recalculate the parent row
        let parentRow = this.deliverables.find((row) => row.id === item.parentId);
        this.calculateStepsFulfillmentForNewRow(parentRow);
      }

      // Set the deliverables again.
      this.$store.dispatch("setDeliverables", this.deliverables);
    },

    // calculateEachSubprojectTotalFulfillment(row) {
    //   if (this.steps.length > 0) {
    //     let totalWeights = 0;
    //     let sumAll = 0;

    //     row.children.forEach((item, idx) => {
    //       // const selectedRow = this.deliverables[idx];
    //       if (typeof item.fulfillment === "number") {
    //         totalWeights += +item.weight;
    //         sumAll += item.fulfillment * item.weight;
    //       }
    //     });
    //     row.fulfillment = sumAll / totalWeights;
    //   }
    // },

    getProgressBarStyles(header, stepsFulfillment) {
      return (stepsFulfillment[header.value] ? Math.round(stepsFulfillment[header.value]) : 0) + "%";
    },

    toggleWidth() {
      this.expandedWidth = !this.expandedWidth;
    },
    getTitle(item, header) {
      if (header.value.includes("step") || !isNaN(item[header.value])) {
        return "";
      } else {
        return item[header.value];
      }
    },
    toggleWeight() {
      if (this.showWeight === "Show Weight") {
        this.showWeight = "Hide Weight";
      } else {
        this.showWeight = "Show Weight";
      }
    },
    mouseLeave(item) {
      if (!this.isDialogOpen) {
        item.showEditDialog = false;
      }
    },
    headerTextFieldOpen(headerText) {
      this.inputValue = headerText;
      this.rules.alreadyExist = (value) => {
        return (
          !this.headers.find((header) => {
            return (
              value.toLowerCase() === header.text.toLowerCase() && value.toLowerCase() !== headerText.toLowerCase()
            );
          }) || "Column name already exist"
        );
      };
    },
    //TODO refactor to only have one copy of this method in one place (AddColumn component)
    calculateRowFulfillment(row) {
      let stepsLength = this.steps.length;
      let closedCount = 0;
      let wipCount = 0;

      // if (stepsLength > 0) {
      this.steps.forEach((step) => {
        closedCount += row[step.identity] === "closed" ? 1 : 0;
        wipCount += row[step.identity] === "wip" ? 0.5 : 0;
        stepsLength -= row[step.identity] === "n.rel." ? 1 : 0;
      });
      // }
      if (stepsLength > 0) {
        let fulfillment = ((closedCount + wipCount) / stepsLength) * 100;
        row.fulfillment = fulfillment;
      } else {
        row.fulfillment = "n.rel.";
      }
    },

    cellBackgroundStyle(status) {
      return {
        backgroundColor: this.getColor(status),
      };
    },

    getColor(status) {
      if (status === "open") return "#FF0000";
      else if (status === "wip") return "orange";
      else if (status === "n.rel.") return "#778899";
      else if (status === "closed") {
        return "#7bea7b";
      } else return null;
    },

    close1(item) {
      setTimeout(() => {
        this.isDialogOpen = false;
        item.showEditDialog = false;
      }, 500);
    },
    close() {},
    cancel() {},
    open(openedCell) {
      this.isDialogOpen = true;
      this.inputValue = openedCell;
    },
    changeCell(item, headerValue) {
      item[headerValue] = this.inputValue;
      item.showEditDialog = false;
      this.isDialogOpen = false;
      this.calculateRowFulfillment(item);
      this.$store.dispatch("setDeliverables", this.deliverables);
    },

    changeHeader(header) {
      if (this.rules.required(this.inputValue.length) === true && this.rules.alreadyExist(this.inputValue) === true) {
        header.text = this.inputValue;

        this.$store.dispatch("setHeaders", this.headers);
      }
    },
    inputChanged(value, param) {
      if (param === "weight") {
        if (value !== "" && value > 0) {
          this.inputValue = value;
        }
      } else {
        this.inputValue = value;
      }
    },
    sortColumn(headerValue) {
      if (this.sortBy === headerValue) {
        this.sortDesc = true;

        if (this.rotateValues[headerValue] === 0) {
          this.sortDesc = false;
        }
      } else {
        this.sortDesc = false;
        this.sortBy = headerValue;
        this.rotateValues.id = 0;
        this.rotateValues.description = 0;
        this.rotateValues.weight = 0;
        this.rotateValues.fulfillment = 0;
      }
    },
    // Added by MMG
    showDelete(idx) {
      this.deliverables[idx].showDeleteIcon = true;
    },
    unShowDelete(idx) {
      this.deliverables[idx].showDeleteIcon = false;
    },
    showColumnDelete(idx) {
      ++idx;
      this.headers[idx].columnDeleteIcon = true;
    },
    unShowColumnDelete(idx) {
      ++idx;
      this.headers[idx].columnDeleteIcon = false;
    },
    conformMessage(message, id, type) {
      if (type === "column") {
        if (this.showWeight === "Show Weight") {
          ++id;
        }
        if (id > 2 && id < this.responsibles.length + 3) {
          this.pressedId = id;
          this.pressedType = "responsible";
        } else {
          this.pressedId = id;
          this.pressedType = "step";
        }
        this.message = `Do you want to delete ${this.headers[id].text} column?`;
      } else {
        this.pressedId = id;
        this.pressedType = "Row";
        this.message = message;
      }

      this.toggleShowConformMessage();
    },
    deleteRow() {
      let id = this.pressedId;
      this.deliverables.splice(id, 1);
      for (id; id < this.deliverables.length; id++) {
        this.deliverables[id].id = this.deliverables[id].id - 1;
      }
      // &
      this.$store.dispatch("setDeliverables", this.deliverables);
      this.toggleShowConformMessage();
    },
    deleteColumn(headerValue) {
      let id = this.pressedId;
      if (headerValue.includes("step")) {
        let stepNumber = id - (this.responsibles.length + 3);
        let lengthOfStep = this.steps.length;
        this.steps.splice(stepNumber, 1);
        this.headers.splice(id, 1);

        for (let a = 0; a < this.steps.length; a++) {
          this.steps[a].identity = `step${a + 1}`;
          this.steps[a].text = `step${a + 1}`;
        }
        let counter1 = 1;
        for (let b = 3 + this.responsibles.length; b < this.headers.length - 1; b++) {
          if (this.headers[b].text.includes("Step")) {
            this.headers[b].text = `Step${counter1}`;
          }
          this.headers[b].value = `step${counter1}`;
          counter1++;
        }

        for (let c = 0; c < this.deliverables.length; c++) {
          for (let d = stepNumber; d < this.steps.length; d++) {
            this.deliverables[c][`step${d + 1}`] = this.deliverables[c][`step${d + 2}`];
          }
          this.calculateRowFulfillment(this.deliverables[c]);

          // Calculate fulfillment for children
          if (this.deliverables[c].isGrouped) {
            this.deliverables[c].children.forEach((child) => {
              this.calculateRowFulfillment(child);
            });
          }

          delete this.deliverables[c][`step${lengthOfStep}`];
        }

        this.$store.dispatch("setHeaders", this.headers);
        this.$store.dispatch("setDeliverables", this.deliverables);
        this.$store.dispatch("setNextColumnName", this.$store.getters.getNextColumnName("Status"));
        this.toggleShowConformMessage();
        util.recalculateAllFulfillments(this.deliverables, this.steps);
      } else if (headerValue.includes("responsible")) {
        for (let y = 0; y < this.deliverables.length; y++) {
          let x = id;
          for (x; x < this.responsibles.length + 3; x++) {
            if (this.deliverables[y][`responsible${x - 1}`]) {
              this.deliverables[y][`responsible${x - 2}`] = this.deliverables[y][`responsible${x - 1}`];
            } else if (!this.deliverables[y][`responsible${x - 1}`]) {
              this.deliverables[y][`responsible${x - 2}`] = "";
            }
          }
        }

        for (let z = 0; z < this.deliverables.length; z++) {
          delete this.deliverables[z][`responsible${this.responsibles.length}`];
        }
        this.headers.splice(id, 1);
        this.responsibles.splice(id - 3, 1);

        for (let e = 0; e < this.responsibles.length; e++) {
          this.responsibles[e].identity = `responsible${e + 1}`;
          this.responsibles[e].text = `Responsible${e + 1}`;
        }
        let counter2 = 1;
        for (let f = 3; f < this.responsibles.length + 3; f++) {
          if (this.headers[f].text.includes("Responsible")) {
            this.headers[f].text = `Responsible${counter2}`;
          }
          this.headers[f].value = `responsible${counter2}`;
          counter2++;
        }

        this.$store.dispatch("setHeaders", this.headers);
        this.$store.dispatch("setResponsibles", this.responsibles);
        this.$store.dispatch("setDeliverables", this.deliverables);
        this.toggleShowConformMessage();
      }
    },
    toggleShowConformMessage() {
      this.showConformMessage = !this.showConformMessage;
    },
  },
};
</script>
