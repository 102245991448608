import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

//TODO check fulfilments decimal points consistency between excel and browser

// Define constants
const STATUS_CLOSED = "closed";
const STATUS_WIP = "wip";
const STATUS_OPEN = "open";
const STATUS_NREL = "n.rel.";

export default class ExcelOperator {
  constructor(
    deliverables,
    steps,
    responsibles,
    stepsFulfillment,
    totalFulfillment
  ) {
    this.deliverables = deliverables;
    this.steps = steps;
    this.responsibles = responsibles;
    this.stepsFulfillment = stepsFulfillment;
    this.totalFulfillment = totalFulfillment;
  }

  async saveAsExcel(props) {
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet("TestSheet");
    this.calculateFirstRow(
      workSheet,
      props.steps,
      props.responsibles,
      props.stepsFulfillment,
      props.totalFulfillment
    );

    const tableRows = this.formTableRows(workSheet, props);
    this.addFormattingFulfillmentsColumns(workSheet, props, tableRows);
    this.addFormattingFirstRow(workSheet, props);
    this.addStepDropDownAndColor(workSheet, props, tableRows);
    this.addfulfillmentFormula(workSheet, props);
    this.addFirstRowFormula(workSheet, props, tableRows);
    this.addChildRowStepsFormula(workSheet, props);

    const tableHeaders = [];
    props.headers.forEach((header) => {
      tableHeaders.push({
        name: header.text,
        filterButton: true,
      });
    });

    workSheet.addTable({
      name: "MyTable",
      ref: "A2",
      headerRow: true,
      style: {
        showRowStripes: true,
      },
      columns: tableHeaders,
      rows: tableRows,
    });

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), "Tasks.xlsx");
  }

  calculateFirstRow(
    workSheet,
    steps,
    responsibles,
    stepsFulfillment,
    totalFulfillment
  ) {
    const emptyRows = [];
    for (let index = 0; index < responsibles.length; index++) {
      emptyRows.push("");
    }

    const stepsStatus = [];
    steps.forEach((step) => {
      stepsStatus.push(stepsFulfillment[step.identity] / 100);
    });

    const firstRow = [
      "",
      "",
      "",
      ...emptyRows,
      ...stepsStatus,
      totalFulfillment / 100,
    ];

    const secRow = ["", "", "", "", "", "", ""];
    workSheet.addRow(firstRow);
  }

  addFormattingFirstRow(workSheet, props) {
    let offset = 3 + props.responsibles.length;
    let firstColumnLetter = workSheet._columns[offset].letter;
    let lastColumnLetter =
      workSheet._columns[offset + props.steps.length].letter;

    //Add data bar formatting
    workSheet.addConditionalFormatting({
      ref: `${firstColumnLetter}1:${lastColumnLetter}1`,
      rules: [
        {
          type: "dataBar",
          color: { argb: "C6EFCE" },
          gradient: false,
          cfvo: [
            { type: "num", value: 0 },
            { type: "num", value: 1 },
          ],
          style: {
            font: { color: { argb: "006100" } },
          },
        },
      ],
    });

    //Add number formatting
    for (let index = offset; index <= offset + props.steps.length; index++) {
      let columnLetter = workSheet._columns[index].letter;
      this.addNumberFormatting(workSheet, columnLetter + "1");
    }
  }

  addFormattingChildRows(workSheet, childRowCell) {
    // let offset = 3 + props.responsibles.length;

    //Add data bar formatting
    workSheet.addConditionalFormatting({
      ref: `${childRowCell}`,
      rules: [
        {
          type: "dataBar",
          color: { argb: "C6EFCE" },
          gradient: false,
          cfvo: [
            { type: "num", value: 0 },
            { type: "num", value: 1 },
          ],
          style: {
            font: { color: { argb: "006100" } },
          },
        },
      ],
    });
    //Add number formatting
    this.addNumberFormatting(workSheet, childRowCell);
  }

  addFirstRowFormula2(workSheet, props, tableRows) {
    const offset = 3 + props.responsibles.length;
    const endPosition = offset + props.steps.length;
    const weightColumnLetter = workSheet._columns[2].letter; // Assuming weight column is column C

    for (let index = offset; index <= endPosition; index++) {
      const statusColumnLetter = workSheet._columns[index].letter;
      const statusColumnFirstCell = statusColumnLetter + "1";
      let excludedWeights = [];
      let childRowCellsCounter = 3;
      let formulaComponents = [];
      let countChildRows = 0;

      for (let i = 1; i <= props.deliverables.length; i++) {
        let deliverable = props.deliverables[i - 1];

        if (deliverable && deliverable.isChild == true) {
          let deliverableLength = deliverable.children.length;
          countChildRows += 1;
          excludedWeights.push(`${weightColumnLetter}${childRowCellsCounter}`);

          for (let j = 1; j <= deliverableLength; j++) {
            formulaComponents.push(`(
              IF(ISNUMBER(${statusColumnLetter}${childRowCellsCounter +
              j}), ${statusColumnLetter}${childRowCellsCounter + j}, 
                IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_CLOSED}", 1, 
                  IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_WIP}", 0.5, 
                    IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_OPEN}", 0, 
                      IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_NREL}", 0, 0)
                    )
                  )
                )
              ) * ${weightColumnLetter}${childRowCellsCounter + j}
            )`);
          }

          childRowCellsCounter += deliverableLength + 1;
        } else {
          formulaComponents.push(`(
            IF(ISNUMBER(${statusColumnLetter}${childRowCellsCounter}), ${statusColumnLetter}${childRowCellsCounter}, 
              IF(${statusColumnLetter}${childRowCellsCounter}="${STATUS_CLOSED}", 1, 
                IF(${statusColumnLetter}${childRowCellsCounter}="${STATUS_WIP}", 0.5, 
                  IF(${statusColumnLetter}${childRowCellsCounter}="${STATUS_OPEN}", 0, 
                    IF(${statusColumnLetter}${childRowCellsCounter}="${STATUS_NREL}", 0, 0)
                  )
                )
              )
            ) * ${weightColumnLetter}${childRowCellsCounter}
          )`);
          childRowCellsCounter += 1;
        }

        // Count the occurrences of "n.rel." in the status column
        const nRelCountFormula = `COUNTIF(${statusColumnLetter}${3}:${statusColumnLetter}${2 +
          tableRows.length}, "n.rel.")`;
        workSheet.getCell(statusColumnFirstCell).value = {
          formula: nRelCountFormula,
        };

        // Create the formula for excluded weights

        // Create the formula that considers the "n.rel." condition
        let formula = `=IF(${nRelCountFormula} = ${tableRows.length -
          countChildRows}, "n.rel.", 
              IFERROR(SUM(${formulaComponents.join(", ")}), "") / 
                (SUM(${weightColumnLetter}${3}:${weightColumnLetter}${2 +
          tableRows.length}) - 
                SUMIF(${statusColumnLetter}${3}:${statusColumnLetter}${2 +
          tableRows.length},
                  "n.rel.", ${weightColumnLetter}${3}:${weightColumnLetter}${2 +
          tableRows.length}) 
                      - SUM(${excludedWeights.length > 0 ? excludedWeights : 0})
                  ))`;
        workSheet.getCell(statusColumnFirstCell).value = {
          formula: formula,
        };
      }
    }
  }

  addFirstRowFormula(workSheet, props, tableRows) {


    const offset = 3 + props.responsibles.length;
    const endPosition = offset + props.steps.length;
    const weightColumnLetter = workSheet._columns[2].letter; // Assuming weight column is column C

    for (let index = offset; index <= endPosition; index++) {
      const statusColumnLetter = workSheet._columns[index].letter;
      const statusColumnFirstCell = statusColumnLetter + "1";
      let excludedWeights = [];
      let childRowCellsCounter = 3;
      let formulaComponents = [];
      let countChildRows = 0;

      for (let i = 1; i <= props.deliverables.length; i++) {
        let deliverable = props.deliverables[i - 1];

        // if (deliverable && deliverable.isChild == true) {
        //   let deliverableLength = deliverable.children.length;
        //   console.log("run child parts");
        //   countChildRows += 1;
        //   excludedWeights.push(`${weightColumnLetter}${childRowCellsCounter}`);


        //   // console.log('got true')
        //   for (let j = 1; j <= deliverableLength; j++) {
        //     formulaComponents.push(`(
        //       IF(ISNUMBER(${statusColumnLetter}${childRowCellsCounter +
        //       j}), ${statusColumnLetter}${childRowCellsCounter + j}, 
        //         IF(${statusColumnLetter}${childRowCellsCounter +
        //       j}="${STATUS_CLOSED}", 1, 
        //           IF(${statusColumnLetter}${childRowCellsCounter +
        //       j}="${STATUS_WIP}", 0.5, 
        //             IF(${statusColumnLetter}${childRowCellsCounter +
        //       j}="${STATUS_OPEN}", 0, 
        //               IF(${statusColumnLetter}${childRowCellsCounter +
        //       j}="${STATUS_NREL}", 0, 0)
        //             )
        //           )
        //         )
        //       ) * ${weightColumnLetter}${childRowCellsCounter + j}
        //     )`);
        //   }

        //   // console.log('child row counter ', childRowCellsCounter);
        //   childRowCellsCounter += deliverableLength + 1;
        //   // console.log('child row counter ', childRowCellsCounter);
        // } else {
        //  let statusColumnCell Value = 

        //   for (let i = 1; i <= props.deliverables.length; i++) {
        //     formula = `=IFERROR((COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_CLOSED}") 
        //     +COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_WIP}")* 0.5) / 
        //     (COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_OPEN}") 
        //     +COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_WIP}") 
        //     +COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_CLOSED}")),"${STATUS_NREL}") * ${weightColumnLetter}${childRowCellsCounter}:${weightColumnLetter}${lastColumnIndex} `;
        //   }


        // formulaComponents.push(`(
        //   (COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_CLOSED}") 
        //   +COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_WIP}")* 0.5) / 
        //   (COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_OPEN}") 
        //   +COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_WIP}") 
        //   +COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_CLOSED}")) * ${weightColumnLetter}${childRowCellsCounter} 
        //   `);
        // childRowCellsCounter += 1;


        formulaComponents.push(`(
          IF(ISNUMBER(${statusColumnLetter}${childRowCellsCounter}), ${statusColumnLetter}${childRowCellsCounter}, 
          (COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_CLOSED}") 
          +COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_WIP}")* 0.5) / 
          (COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_OPEN}") 
          +COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_WIP}") 
          +COUNTIF(${statusColumnLetter}${childRowCellsCounter},"${STATUS_CLOSED}")) * ${weightColumnLetter}${childRowCellsCounter})
          )`);
        childRowCellsCounter += 1;
      }
      // }

      // Count the occurrences of "n.rel." in the status column
      const nRelCountFormula = `COUNTIF(${statusColumnLetter}${3}:${statusColumnLetter}${2 +
        tableRows.length}, "n.rel.")`;
      workSheet.getCell(statusColumnFirstCell).value = {
        formula: nRelCountFormula,
      };

      // Create the formula for excluded weights

      // Create the formula that considers the "n.rel." condition
      let formula = `=IF(${nRelCountFormula} = ${tableRows.length -
        countChildRows}, "n.rel.", 
              IFERROR(SUM(${formulaComponents.join(", ")}), "") / 
                (SUM(${weightColumnLetter}${3}:${weightColumnLetter}${2 +
        tableRows.length}) - 
                SUMIF(${statusColumnLetter}${3}:${statusColumnLetter}${2 +
        tableRows.length},
                  "n.rel.", ${weightColumnLetter}${3}:${weightColumnLetter}${2 +
        tableRows.length}) 
                      - SUM(${excludedWeights.length > 0 ? excludedWeights : 0})
                  ))`;
      workSheet.getCell(statusColumnFirstCell).value = {
        formula: formula,
      };
    }
    // }

    // const offset = 3 + props.responsibles.length;
    // const endPosition = offset + props.steps.length;
    // const weightColumnLetter = workSheet._columns[2].letter; // Assuming weight column is column C
    // const lastColumnIndex = props.deliverables.length + 2;

    // for (let index = offset; index <= endPosition; index++) {
    //   const statusColumnLetter = workSheet._columns[index].letter;
    //   const statusColumnFirstCell = statusColumnLetter + "1";
    //   let childRowCellsCounter = 3;
    //   let weightColumnValue = workSheet.getCell(
    //     weightColumnLetter + childRowCellsCounter
    //   ).value;
    //   let formula;
    //   for (let i = 1; i <= props.deliverables.length; i++) {
    //     formula = `=IFERROR((COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_CLOSED}") 
    //     +COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_WIP}")* 0.5) / 
    //     (COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_OPEN}") 
    //     +COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_WIP}") 
    //     +COUNTIF(${statusColumnLetter}${childRowCellsCounter}:${statusColumnLetter}${lastColumnIndex},"${STATUS_CLOSED}")),"${STATUS_NREL}") * ${weightColumnLetter}${childRowCellsCounter}:${weightColumnLetter}${lastColumnIndex} `;
    //   }

    //   workSheet.getCell(statusColumnFirstCell).value = {
    //     formula: formula,
    //   };
    // }
  }

  addFirstRowFormula3(workSheet, props, tableRows) {
    const offset = 3 + props.responsibles.length;
    const endPosition = offset + props.steps.length;
    const weightColumnLetter = workSheet._columns[2].letter; // Assuming weight column is column C
    const lastColumnIndex = props.deliverables.length + 2;

    for (let index = offset; index <= endPosition; index++) {
      const statusColumnLetter = workSheet._columns[index].letter;
      const statusColumnFirstCell = statusColumnLetter + "1";
      const firstDataRow = 3; // Starting row for data
      const lastDataRow = firstDataRow + tableRows.length - 1;

      // Calculate the SUMPRODUCT and SUM with conditions to ignore STATUS_NREL
      const formula = `=IFERROR(
        SUMPRODUCT(
          (((${statusColumnLetter}${firstDataRow}:${statusColumnLetter}${lastDataRow} = "${STATUS_CLOSED}") +
            (${statusColumnLetter}${firstDataRow}:${statusColumnLetter}${lastDataRow} = "${STATUS_WIP}") * 0.5) *
            (${weightColumnLetter}${firstDataRow}:${weightColumnLetter}${lastDataRow})) *
            ((${statusColumnLetter}${firstDataRow}:${statusColumnLetter}${lastDataRow} <> "${STATUS_NREL}") * 1)
        ) /
        SUMIFS(
          ${weightColumnLetter}${firstDataRow}:${weightColumnLetter}${lastDataRow},
          ${statusColumnLetter}${firstDataRow}:${statusColumnLetter}${lastDataRow},
          "<> ${STATUS_NREL}"
        ),
        "${STATUS_NREL}"
      )`;

      workSheet.getCell(statusColumnFirstCell).value = {
        formula: formula,
      };
    }
  }

  addChildRowStepsFormula(workSheet, props) {
    const offset = 3 + props.responsibles.length;
    const endPosition = offset + props.steps.length;
    const weightColumnLetter = workSheet._columns[2].letter; // Assuming weight column is column C

    for (let index = offset; index <= endPosition; index++) {
      const statusColumnLetter = workSheet._columns[index].letter;
      let childRowCellsCounter = 3;

      for (let i = 1; i <= props.deliverables.length; i++) {
        let deliverable = props.deliverables[i - 1];
        const ChildRowsFormulaComponents = [];
        let deliverableLength;
        const childRowCell = statusColumnLetter + `${childRowCellsCounter}`;

        if (deliverable && deliverable.isChild == true) {
          this.addFormattingChildRows(workSheet, childRowCell);

          deliverableLength = deliverable.children.length;

          for (let j = 1; j <= deliverableLength; j++) {
            ChildRowsFormulaComponents.push(`(
              IF(ISNUMBER(${statusColumnLetter}${childRowCellsCounter +
              j}), ${statusColumnLetter}${childRowCellsCounter + j}, 
                IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_CLOSED}", 1, 
                  IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_WIP}", 0.5, 
                    IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_OPEN}", 0, 
                      IF(${statusColumnLetter}${childRowCellsCounter +
              j}="${STATUS_NREL}", 0, 0)
                    )
                  ) 
                )
              ) * ${weightColumnLetter}${childRowCellsCounter + j}
            )`);
          }
          // Count the occurrences of "n.rel." in the status column
          let nRelCountFormulaChild = `COUNTIF(${statusColumnLetter}${childRowCellsCounter +
            1}:${statusColumnLetter}${childRowCellsCounter +
            deliverableLength}, "n.rel.")`;
          // workSheet.getCell(childRowCell).value = {
          //   formula: nRelCountFormulaChild
          // };

          // Create the formula that considers the "n.rel." condition
          let childRowFormula = `=IF(${nRelCountFormulaChild} = ${deliverableLength}, "n.rel.", 
            IFERROR(SUM(${ChildRowsFormulaComponents.join(", ")}), "") / 
              (SUM(${weightColumnLetter}${childRowCellsCounter +
            1}:${weightColumnLetter}${childRowCellsCounter +
            deliverableLength}) - 
              SUMIF(${statusColumnLetter}${childRowCellsCounter +
            1}:${statusColumnLetter}${childRowCellsCounter + deliverableLength},
                "n.rel.", ${weightColumnLetter}${childRowCellsCounter +
            1}:${weightColumnLetter}${childRowCellsCounter + deliverableLength})))`;

          workSheet.getCell(childRowCell).value = {
            formula: childRowFormula,
          };

          console.log(
            `child row cell formula and the cell is ${childRowCell}`,
            workSheet.getCell(childRowCell).value
          );
          childRowCellsCounter += deliverableLength;
        }
        childRowCellsCounter++;
      }
    }
  }

  addDropDownAndColor(workSheet, targetCell) {
    workSheet.getCell(targetCell).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [
        `"${STATUS_OPEN},${STATUS_WIP},${STATUS_CLOSED},${STATUS_NREL}"`,
      ],
    };
    this.addColorFormatting(workSheet, targetCell);
  }

  addStepDropDownAndColor(workSheet, props, tableRows) {

    let columnOffset = 3 + props.responsibles.length;
    let endPosition = columnOffset + props.steps.length - 1;

    for (let index = columnOffset; index <= endPosition; index++) {
      let inc = 3;

      let columnLetter = workSheet._columns[index].letter;

      for (let i = 1; i <= props.deliverables.length; i++) {
        let targetCell;
        let deliverable = props.deliverables[i - 1];

        if (deliverable && deliverable.isChild == true) {
          inc += 1;
          for (let j = 1; j < deliverable.children.length; j++) {
            targetCell = `${columnLetter}${inc}`;
            this.addDropDownAndColor(workSheet, targetCell);
            inc += 1;
          }
        }
        targetCell = `${columnLetter}${inc}`;
        this.addDropDownAndColor(workSheet, targetCell);
        inc += 1;
      }
    }
  }

  addFormattingFulfillmentsColumns(workSheet, props, tableRows) {
    let offset = 3 + props.responsibles.length + props.steps.length;
    let columnLetter = workSheet._columns[offset].letter;

    //Add data bar formatting
    workSheet.addConditionalFormatting({
      ref: `${columnLetter}${3}:${columnLetter}${tableRows.length + 2}`,
      rules: [
        {
          type: "dataBar",
          color: { argb: "C6EFCE" },
          gradient: false,
          cfvo: [
            { type: "num", value: 0 },
            { type: "num", value: 1 },
          ],
          style: {
            font: { color: { argb: "006100" } },
          },
        },
      ],
    });

    //Add number formatting
    for (let index = 1; index <= tableRows.length; index++) {
      this.addNumberFormatting(workSheet, columnLetter + (index + 2));
    }
  }

  addfulfillmentFormulaHelper(
    firstColumnLetter,
    lastColumnLetter,
    childRowCellsCounter
  ) {
    let formula = `=IFERROR((COUNTIF(${firstColumnLetter}${childRowCellsCounter}:${lastColumnLetter}${childRowCellsCounter},"${STATUS_CLOSED}") 
          +COUNTIF(${firstColumnLetter}${childRowCellsCounter}:${lastColumnLetter}${childRowCellsCounter},"${STATUS_WIP}")* 0.5) / 
          (COUNTIF(${firstColumnLetter}${childRowCellsCounter}:${lastColumnLetter}${childRowCellsCounter},"${STATUS_OPEN}") 
          +COUNTIF(${firstColumnLetter}${childRowCellsCounter}:${lastColumnLetter}${childRowCellsCounter},"${STATUS_WIP}") 
          +COUNTIF(${firstColumnLetter}${childRowCellsCounter}:${lastColumnLetter}${childRowCellsCounter},"${STATUS_CLOSED}")) ,"${STATUS_NREL}")`;
    return formula;
  }

  addfulfillmentFormula(workSheet, props) {
    let offset = 3 + props.responsibles.length + props.steps.length;
    let fulfillmentColumnLetter = workSheet._columns[offset].letter;
    let columnOffset = 3 + props.responsibles.length;
    let childRowCellsCounter = 3;

    let firstColumnLetter = workSheet._columns[columnOffset].letter;
    let lastColumnLetter =
      workSheet._columns[columnOffset + props.steps.length - 1].letter;

    for (let i = 1; i <= props.deliverables.length; i++) {
      const deliverable = props.deliverables[i - 1];
      let deliverableLength;

      if (deliverable && deliverable.isChild === true) {
        deliverableLength = deliverable.children.length;

        for (let j = 1; j <= deliverableLength; j++) {
          let formula = this.addfulfillmentFormulaHelper(
            firstColumnLetter,
            lastColumnLetter,
            childRowCellsCounter + j
          );
          let fulfillmentCell = `${fulfillmentColumnLetter}${childRowCellsCounter +
            j}`;
          workSheet.getCell(fulfillmentCell).value = {
            formula: formula,
          };
        }
        childRowCellsCounter += deliverableLength + 1;
      } else {
        let formula = this.addfulfillmentFormulaHelper(
          firstColumnLetter,
          lastColumnLetter,
          childRowCellsCounter
        );
        let fulfillmentCell = `${fulfillmentColumnLetter}${childRowCellsCounter}`;
        workSheet.getCell(fulfillmentCell).value = {
          formula: formula,
        };
        childRowCellsCounter += 1;
      }
    }
  }

  addNumberFormatting(workSheet, cell) {
    workSheet.getCell(cell).numFmt = "0%";
  }

  formTableRows(workSheet, props) {
    const tableRows = [];

    props.deliverables.forEach((item, index) => {
      let itemValues = [];

      if (item.isChild) {
        const responsibles = [];

        props.responsibles.forEach((r) => {
          responsibles.push(item[r.identity]);
        });


        const firstRow = [
          item.id,
          item.description,
          item.weight,
          ...responsibles,
        ];

        tableRows.push(firstRow);

        item.children.forEach((row, index) => {
          let childValues = [];
          childValues.push(row.id);
          childValues.push(row.description);
          childValues.push(parseFloat(row.weight));

          props.responsibles.forEach((r) => {
            childValues.push(row[r.identity]);
          });

          props.steps.forEach((s) => {
            childValues.push(row[s.identity]);
          });

          tableRows.push(childValues);
        });
      } else {
        itemValues.push(item.id);
        itemValues.push(item.description);
        itemValues.push(parseFloat(item.weight));

        props.responsibles.forEach((r) => {
          itemValues.push(item[r.identity]);
        });

        props.steps.forEach((s) => {
          itemValues.push(item[s.identity]);
        });

        tableRows.push(itemValues);
      }
    });
    return tableRows;
  }

  addColorFormatting(workSheet, targetCell) {
    workSheet.addConditionalFormatting({
      ref: targetCell,
      rules: [
        {
          type: "cellIs",
          operator: "equal",
          color: { argb: "FF00FF00" },
          formulae: [`"${STATUS_CLOSED}"`],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "C6EFCE" },
            },
            font: { color: { argb: "006100" } },
          },
        },
      ],
    });

    workSheet.addConditionalFormatting({
      ref: targetCell,
      rules: [
        {
          type: "cellIs",
          operator: "equal",
          color: { argb: "FF00FF00" },
          formulae: [`"${STATUS_OPEN}"`],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFC7CE" },
            },
            font: { color: { argb: "9C0006" } },
          },
        },
      ],
    });

    workSheet.addConditionalFormatting({
      ref: targetCell,
      rules: [
        {
          type: "cellIs",
          operator: "equal",
          color: { argb: "FF00FF00" },
          formulae: [`"${STATUS_WIP}"`],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFEB9C" },
            },
            font: { color: { argb: "9C6500" } },
          },
        },
      ],
    });

    workSheet.addConditionalFormatting({
      ref: targetCell,
      rules: [
        {
          type: "cellIs",
          operator: "equal",
          color: { argb: "FF00FF00" },
          formulae: [`"${STATUS_NREL}"`],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "" },
            },
          },
        },
      ],
    });
  }

  async loadExcel(props) {
    let laodTable = [];
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(props.file);
    return new Promise((resolve) => {
      reader.onload = async () => {
        const buffer = reader.result;
        const workbook = await wb.xlsx.load(buffer);

        // let sheet = workbook.getWorksheet("TestSheet");
        let sheet = workbook.getWorksheet(1);

        let firstStepColumnNumber = 4;
        let lastStepColumnNumber = 0;
        let firstResponsibleColumnNumber = 0;
        let lastResponsibleColumnNumber = 0;
        let offset = 3;
        let headerValues;
        sheet.eachRow((row, rowNumber) => {
          if (rowNumber < 2) {
            return;
          }

          //Remove the unwanted empty row that exceljs loads
          let rowValues = row.values;
          rowValues.shift();

          if (rowNumber == 2) {
            headerValues = rowValues;
            return;
          }

          if (rowNumber == 3) {
            rowValues.some((value, index) => {
              if (
                value == `${STATUS_OPEN}` ||
                value == `${STATUS_WIP}` ||
                value == `${STATUS_CLOSED}` ||
                value == `${STATUS_NREL}`
              ) {
                firstStepColumnNumber = index + 1;
                return true;
              }
            });

            if (firstStepColumnNumber - offset != 1) {
              firstResponsibleColumnNumber = offset + 1;
              lastResponsibleColumnNumber = firstStepColumnNumber - 1;
            }
            lastStepColumnNumber = rowValues.length - 1;
          }

          const loadingObject = {
            id: row.getCell(1).value,
            description: row.getCell(2).value,
            weight: row.getCell(3).value,
            showEditDialog: false,
          };

          let counter = 1;
          if (firstResponsibleColumnNumber != 0) {
            for (
              let start = firstResponsibleColumnNumber;
              start <= lastResponsibleColumnNumber;
              start++
            ) {
              if (counter == 1) {
                loadingObject["responsible"] = row.getCell(start).value;
              } else {
                loadingObject["responsible" + counter] = row.getCell(
                  start
                ).value;
              }
              counter++;
            }
          }

          counter = 1;
          for (
            let start = firstStepColumnNumber;
            start <= lastStepColumnNumber;
            start++
          ) {
            loadingObject["step" + counter] = row.getCell(start).value;
            counter++;
          }
          loadingObject["fulfillment"] = row.getCell(rowValues.length).value;

          laodTable.push(loadingObject);
        });

        //Forming responsibles array (if any) to return it as props to be used for loading in the browser

        let responsibles = [];
        let responsiblesCounter = 1;
        if (firstResponsibleColumnNumber != 0) {
          for (
            let i = firstResponsibleColumnNumber;
            i <= lastResponsibleColumnNumber;
            i++
          ) {
            if (responsiblesCounter == 1) {
              responsibles.push({
                text: headerValues[i - 1],
                identity: `responsible`,
              });
            } else {
              responsibles.push({
                text: headerValues[i - 1],
                identity: `responsible${responsiblesCounter}`,
              });
            }
            responsiblesCounter++;
          }
        }
        //Forming steps array to return it as props to be used for loading in the browser
        const steps = [];
        let stepCounter = 1;
        for (let i = firstStepColumnNumber; i <= lastStepColumnNumber; i++) {
          steps.push({
            text: headerValues[i - 1],
            identity: `step${stepCounter}`,
          });
          stepCounter++;
        }

        //Forming headers array to return it as props to be used for loading in the browser
        //The following 'headers' object was copied from store's statusTable. Refactoring may be needed
        //to use only one

        const headers = [
          {
            text: "No",
            align: "start",
            value: "id",
            sortable: true,
            editable: false,
            headerEditable: false,
          },
          { text: "Description", value: "description", editable: true },
          { text: "Weight", value: "weight", editable: true },

          { text: "Fulfillment", value: "fulfillment", editable: false },
        ];

        //Add responsibles columns (if any) to headers array
        responsiblesCounter = 1;
        if (responsibles.length > 0) {
          for (
            let i = firstResponsibleColumnNumber;
            i <= lastResponsibleColumnNumber;
            i++
          ) {
            if (responsiblesCounter == 1) {
              headers.splice(i - 1, 0, {
                text: `${headerValues[i - 1]}`,
                value: "responsible",
                editable: true,
                sortable: false,
                headerEditable: true,
              });
            } else {
              headers.splice(i - 1, 0, {
                text: `${headerValues[i - 1]}`,
                value: `responsible${responsiblesCounter}`,
                editable: true,
                sortable: false,
                headerEditable: true,
              });
            }

            responsiblesCounter++;
          }
        }

        //Add steps columns to headers array
        stepCounter = 1;
        for (let i = firstStepColumnNumber; i <= lastStepColumnNumber; i++) {
          headers.splice(i - 1, 0, {
            text: `${headerValues[i - 1]}`,
            value: `step${stepCounter}`,
            editable: true,
            sortable: false,
            headerEditable: true,
          });
          stepCounter++;
        }
        const props = {
          deliverables: laodTable,
          responsibles: responsibles,
          steps: steps,
          headers: headers,
        };
        resolve(props);
      };
    }).then((props) => {
      return props;
    });
  }
}
