<template>
  <div>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { PayPal } from "../utils/constants";

export default {
  name: "PayPalButton",

  data() {
    return {
      planId: PayPal.planID,
    };
  },

  async mounted() {
    // await this.checkSubscriptionStatus(); // Check if user is already subscribed
    this.loadPayPalScript();
  },

  methods: {
    // Dynamically load the PayPal SDK with client-id from environment variables
    loadPayPalScript() {
      const scriptTag = document.createElement("script");
      scriptTag.src = `https://www.paypal.com/sdk/js?client-id=${PayPal.subClientId}&vault=true&intent=subscription`;
      scriptTag.onload = () => {
        this.renderPayPalButton();
      };
      scriptTag.onerror = () => {
        console.error("Failed to load PayPal script.");
      };

      document.head.appendChild(scriptTag);
    },

    renderPayPalButton() {
      if (typeof paypal === "undefined") {
        console.error("PayPal SDK not loaded.");
        return;
      }
      paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe", // Subscription-specific label
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: this.planId, // Pass the plan ID from props
            });
          },
          onApprove: async (subscriptionData, actions) => {
            // Handle successful subscription
            // console.log("Subscription completed with ID: ", data);
            const { facilitatorAccessToken, orderID, paymentSource, subscriptionID } = subscriptionData;
            // Set PayPal Customer Number in Cognito
            const user = await Auth.currentAuthenticatedUser();

            // console.log("user props", user);
            console.log("sub data after approve", subscriptionData);

            const attributes = {
              "custom:subscriptionID": subscriptionID, // Storing subscription ID as PayPal Customer Number
              "custom:pro": "active",
              // "custom:lastPayment": "active",
            };

            await Auth.updateUserAttributes(user, attributes);
            this.$store.dispatch("setSubStatus", "active");
            this.$store.dispatch("setSubID", subscriptionID);
            this.$store.dispatch("setUserToken", facilitatorAccessToken);
            this.$store.dispatch("setDeleteDialog", {});
            this.$store.dispatch("addToast", {
              severity: "success",
              life: 3000,
              summary: "Subscribed",
              detail: "You successfully subscribed to pro plan.",
            });
          },
          onError: (err) => {
            // Handle errors during subscription creation
            console.error("PayPal Subscription Error:", err);
            this.$store.dispatch("setSubStatus", "inactive");
            this.$store.dispatch("setSubID", "");
            this.$store.dispatch("setDeleteDialog", {});
            this.$store.dispatch("addToast", {
              severity: "error",
              life: 3000,
              summary: "Subscription failed",
              detail: "Subscription failed. Please try again.",
            });
          },
        })
        .render("#paypal-button-container");
    },
  },
};
</script>
