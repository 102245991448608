<template>
    <button :class="buttonClass" :type="buttonType" :disabled="mode" @click="$emit('click')">
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    props: ["buttonClass", "buttonType", "mode"],
    emits: ["click"],
  };
  </script>
  
  <style lang="less" scoped>
  @import "../../assets/styles/variables.less"; 
  
  button {
    border-style: none;
  }
  
  .btn {
    border-radius: @kk-border-radius;
    font-weight: 500;
    letter-spacing: 0.089em;
    outline: none;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    color: @kk-dark-btn-text;
  
    &:disabled {
      background-color: @kk-color-background;
      color: @kk-color-gray-1;
      cursor: not-allowed;
      pointer-events: all;
    }
  
    &::before {
      background-color: currentColor;
      border-radius: inherit;
      bottom: 0;
      color: inherit;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }
  
    &:hover::before {
      opacity: 0.08;
    }
  
    &-default,
    &-error,
    &-info {
      font-size: 0.875rem;
      cursor: pointer;
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
    }
  
    &-primary {
      box-shadow: @kk-btn-primary-box-shadow;
      color: @kk-color-light-white;
      background-color: @kk-primary-btn-bg-color !important;
      border-color: @kk-primary-btn-bg-color !important;
    }
  
    &-info {
      color: @kk-btn-info-color !important;
    }
  
    &-error {
      color: @kk-btn-error-color !important;
    }
  
    &-block {
      min-width: 100% !important;
      background-color: #f5f5f5;
      box-shadow: @kk-btn-primary-box-shadow;
    }
  }
  </style>
  