<template>
  <div class="home">
    <StatusTable />
    <confirm-delete v-if="showDialog" />
    <!-- profile popup -->
    <!-- <custom-modal modal-width="100vw" @close="setProfileDialog" position="absolute" right="-23px" heiht="100vh">
      <template #body> -->
    <profile v-if="profileDialog" />
    <!-- </template>
    </custom-modal> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { PayPal } from "../utils/constants";
import StatusTable from "@/components/StatusTable.vue";
import ConfirmDelete from "../components/common/ConfirmDelete.vue";
import { mapState } from "vuex";
import Profile from "../components/navbar/Profile.vue";
import { Auth } from "aws-amplify";

export default {
  name: "Home",
  components: {
    StatusTable,
    ConfirmDelete,
    Profile,
  },

  computed: {
    subscriptionID() {
      return this.statusTable.authUser?.subID;
    },
    ...mapState(["statusTable"]),
    showDialog() {
      return this.statusTable.deleteDialog?.show;
    },
    profileDialog() {
      return this.statusTable.showProfileDialog;
    },
    nextBillingTime() {
      return this.$store.getters.getNextBillingtime;
    },
  },

  methods: {
    setProfileDialog() {
      this.$store.dispatch("toggleProfileDialog");
    },
  },
};
</script>
