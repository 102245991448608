<template>
  <div class="amplify__wrapper">
    <amplify-authenticator>
      <amplify-sign-in slot="sign-in">
        <div slot="federated-buttons"></div>
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style scoped>
.amplify__wrapper {
  text-align: center;
}
</style>
