const HotkeyScopes = {
  // Default
  All: "all",

  ProcessDesigner: "process-designer",
  Search: "search",
  EditToolBox: "edit-tool-box",
  Alignments: "alignments",
  Colors: "colors",
  Icons: "icons",
  CustomStyles: "custom-styles",
  SelectFont: "select-font",
  // Sidebar
  Sidebar: {
    Teams: "teams",
    Players: "players",
    Processes: "processes",
    Options: "options",
  },
  SidebarContextMenu: "sidebar-context-menu",
  DeleteConfirmDialog: "delete-confirm-dialog",
  AddMemberDialog: "add-member-dialog",
  ProcessFlow: "process-flow",
  CellContextMenu: "cell-context-menu",
  goto: {
    shortcuts: "shortcuts",
    favorites: "favorites",
    search: "search",
  },
  MoreCommands: "more-commands",
  DropDown: "drop-down",
  FormulaSearch: "formula-search",
  QuickAccessToolbar: "quick-access-toolbar",
  Trash: "trash",
  GotoBox: "go-to-box",
  SeriesBox: "series-box",
  SmartSearch: "smart-search",
};

const PayPal = {
  planID: "P-93P09919XH729912WM4SI7AY",
  testPlanId: "P-5C71509159108260BM4W3XJY",
  subClientId: "AeJ5P7QfJqbeljfe_9w_bPtr62CR4t9yHnlB5SrbM4PdwkJ2GtBtvZgEfsyXluqSdCppf4cUvbMcPBwT",
  testSubClientId: "AbICX6Wj5pGOZNjgnIdl2h2rZrTVSbJUaZs8wE2W965fWEoPi9pe4Z7n32Cy0M4MZqyA4uzPHmP5Apah",
  clientID: "AeJ5P7QfJqbeljfe_9w_bPtr62CR4t9yHnlB5SrbM4PdwkJ2GtBtvZgEfsyXluqSdCppf4cUvbMcPBwT",
  SecrectKey: "EJXGagWr-ihvlZJJacX6pweQkrMx6Bn3Jj0NusjFnAcVmprdamxgJDgSdFm_Glt0k-CoRXZDvT1s-6sH",
  testClientID: "AbICX6Wj5pGOZNjgnIdl2h2rZrTVSbJUaZs8wE2W965fWEoPi9pe4Z7n32Cy0M4MZqyA4uzPHmP5Apah",
  testSecretKey: "EADGhcAS8dhZ6rcXyl_YlMTJ94PN_J4F1hWp6lD6hbcCUXUaOAueUlUcpJ099X9mwyDg85s3N74Qwd9V",
  payPalTokenUrl: "https://api-m.paypal.com/v1/oauth2/token",
  PayPaSubUrl: "https://api-m.paypal.com/v1/billing/subscriptions",
  testTokenUrl: "https://api-m.sandbox.paypal.com/v1/oauth2/token",
  testSubUrl: "https://api-m.sandbox.paypal.com/v1/billing/subscriptions",
};

export { HotkeyScopes, PayPal };
