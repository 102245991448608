<template>
  <transition name="modal-fade">
    <div class="modal__backdrop" @click="close">
      <div class="modal" :style="{ width: modalWidth, ...extraStyle }" @click.stop="">
        <div class="model__inner">
          <header v-if="$slots.header" class="modal__header">
            <slot name="header"> </slot>
          </header>

          <section v-if="$slots.body" class="modal__body" :style="{ padding: bodyPadding ? bodyPadding : '0px' }">
            <slot name="body"> </slot>
          </section>

          <footer v-if="$slots.footer" class="modal__footer">
            <slot name="footer"> </slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CustomModal",
  props: ["modalWidth", "bodyPadding", "heiht", "position", "right"],
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    extraStyle() {
      return {
        height: this.heiht,
        position: this.position,
        right: this.right,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/variables.less";

.modal {
  background: @kk-color-white;
  border-radius: @kk-border-radius;
  margin: 1.5rem;
  color: @kk-dark-btn-text;

  &__backdrop {
    z-index: 102;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: @kk_modal_backdrop_bg;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    padding: 8px 16px;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  &__body {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }

  &__header {
    font-size: 12px;
    font-weight: 400;
    padding: 0.5rem 0.8rem;
    // padding: 0px 24px 0px 10px;
  }
}

.modal-fade {
  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }
}
</style>
