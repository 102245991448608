import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/styles/style.css";
import clickOutside from "./clickOutside";
import Amplify, { Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
// import { createI18n } from "vue-i18n";
import VueI18n from "vue-i18n";
import { applyPolyfills, defineCustomElements } from "@aws-amplify/ui-components/loader";
import en_US from "./i18n/en_US";
import de from "./i18n/de";

Vue.config.ignoredElements = [/amplify-\w*/];

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

// Vue.use(VueI18n);

//  const i18n = VueI18n({
//   locale: "en_US",
//   messages: { en_US, de },
// });

// const app = withUUID(createApp(App));
// app.use(store);
// app.use(router);
// app.use(vuetify);

// app.use(i18n);

// app.directive("outside-click", clickOutside);

// app.mount("#app");

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
