<template>
  <div class="kk-toasts">
    <div v-for="toast of toasts" :key="`kk-toasts__toast${toast.id}`" :class="clazz(toast)">
      <i class="kk-toasts__toast-close-btn mdi mdi-close" @click="removeToast(toast)"></i>
      <b v-if="toast.summary" class="mr-8">{{ toast.summary }}</b>
      <small
        v-if="toast.detail"
        :class="{ 'kk-toasts__toast--with-action': !!toast.action }"
        @click="toast.action ? toast.action() : null"
      >
        {{ toast.detail }}
      </small>
    </div>
  </div>
</template>

<script>
// import CustomIcon from "./commons/CustomIcon.vue";

export default {
  //   components: { CustomIcon },
  computed: {
    toasts() {
      return this.$store.getters.getToasts;
    },
  },
  methods: {
    clazz(toast) {
      return `kk-toasts__toast kk-toasts__toast-${toast.severity}`;
    },
    removeToast(toast) {
      this.$store.dispatch("removeToast", toast.id);
    },
  },
};
</script>

<style lang="less" scoped>
.kk-toasts {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 250;

  &__toast {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 1rem;
    width: 20rem;
    border-radius: 0.75rem;

    &-info {
      color: #696cff;
      background-color: #e9e9ff;
    }

    &-error {
      color: #ff5757;
      background-color: #ffe7e6;
    }

    &-success {
      color: #1ea97c;
      background-color: #e4f8f0;
    }

    &-warn {
      color: #cc8925;
      background-color: #fff2e2;
    }

    &-close-btn {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      color: #232f3e;
      color: inherit;

      &:hover {
        color: #000;
      }
    }

    &--with-action {
      cursor: pointer;
    }
  }
}
</style>
