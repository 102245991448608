module.exports = {
  appName: "Kyra",
  mybusiness: {
    title: "My Business",
    subscription: {
      title: "Subscription",
      rates_title: "Rate",
      usage_title: "Usage",
      quota_title: "Quota",
      note1: "Rates are USD/Month per Team/User/Design/K-Requests Count",
      note2: "Totals are monthly and do not include Tax, AWS or other fees (check AWS Billing for the actual cost)",
      teams: "Teams",
      users: "Users",
      designs: "Designs",
      k_requests: "1K Reqs",
      total: "Total",
      "close-btn": "Close",
      "update-btn": "Update",
      load: {
        failed: {
          summary: "Failed",
          detail: "Failed to load subscription details, please try again.",
        },
      },
      quota: {
        "unsubscribed-team-create": {
          summary: "We are happy you want to do more! Great!",
          detail: "Subscribe to create a new team",
        },
        "private-quota-reached": {
          summary: "We are happy you want to do more! Great!",
          detail: "Subscribe to do more designs.",
        },
        "team-quota-reached": {
          summary: "We are happy you want to do more! Great!",
          detail: "It's your team owner ({emailAddress}) who can extend the limits. Click here to send an email",
        },
      },
    },
  },
  subscribead: {
    title: "subscribe",
    note: "we use aws-marketplace for managing and billing subscription. for doing so, {needAwsMarketplaceAccount}. {viewKyraOnAwsMarketplace}",
    needAwsMarketplaceAccount: "you need to have an aws-marketplace account",
    viewKyraOnAwsMarketplace: "click here to view kyra.blue on aws-marketplace",
    closeBtn: "Close",
    subscribeBtn: "Subscribe",
    hideThisLink: "don't want to see this again, hide it",
    awsmpAppLink: {
      dev: "https://aws.amazon.com/marketplace/pp/prodview-ocnt6x4vmxaoi",
      prod: "https://aws.amazon.com/marketplace/pp/prodview-q54ohpbnmp6qw",
    },
  },
  setting: {
    title: "Setting",
    modObjType: {
      header: "Manage Modules/Object-types/Objects options",
      downloadHeader: "Download options as a CSV file",
      downloadBtn: "Download CSV",
      uploadHeader: "Upload and replace options from a CSV file",
      uploadPlaceholder: "Select the CSV file",
      uploadBtn: "Upload CSV",
      uploadNote: "Use the downloaded file from {downloadHeader} as a template.",
      uploadNote2: "The uploading CSV file MUST match the template structure.",
      resetHeader: "Reset options to Defaults",
      resetBtn: "Reset to Defaults",
      resetNote: "BE CAREFUL, IRREVERSIBLE!",
      startOpList: " Start with empty options list",
      resetOpList: "  Reset to empty options list",
      uploadSuccess: {
        summary: "Success",
        detail: "Options CSV file uploaded.",
      },
      uploadFailed: {
        summary: "Failed",
        detail: "Failed to read CSV file content, please try again.",
      },
      resetSuccess: {
        summary: "Success",
        detail: "Options were reset to defaults.",
      },
    },
    emptyModule: {
      resetSuccess: {
        summary: "Success",
        detail: "Options were reseted to Empty List",
      },
    },
    closeBtn: "Close",
    menu: {
      general: "General",
      advanced: "Advanced",
      accessibility: "Accessibility",
      quickAccsToolbar: "Quick Access Toolbar",
    },
  },
  toolbox: {
    modulesHeader: "Modules",
    objectTypesHeader: "Object Types",
    objectsHeader: "Objects",
    modules: "Modules",
    objectTypes: "Object Types",
    objects: "Objects",
    list: {
      failed: {
        summary: "Failed",
        detail: "Failed to load {category} options, please try again.",
      },
    },
    uploadModObjType: {
      failed: {
        summary: "Failed",
        detail: "Failed to upload modules/object-types/objects csv file, please try again.",
      },
    },
    resetModObjTypeToDefaults: {
      failed: {
        summary: "Failed",
        detail: "Failed to reset modules/object-types/objects options to default, please try again.",
      },
    },
    reset: {
      failed: {
        summary: "Failed",
        detail: "Failed to reset {category} options, please try again.",
      },
    },
  },
  designsList: {
    createPosting: "Create Posting",
    // deletionSuccess: {
    //   summary: "Success",
    //   detail: "Process design deleted successfully.",
    // },
    // deletionFailed: {
    //   summary: "Failed",
    //   detail: "Failed to delete process design.",
    // },
  },
  signin: {
    header: "Willkommen zurück",
  },
  signout: {
    failed: {
      summary: "Failed",
      detail: "Failed to sign out, please reload the page and try again.",
    },
  },
  profile: {
    emailNotVerified: "not verified",
    confirmBtn: "Confirm",
    loadInfo: {
      failed: {
        summary: "Failed",
        detail: "Failed to load your profile info, please reload the page.",
      },
    },
    changePassword: {
      header: "Change Password",
      currentLbl: "Enter your current password",
      currentHint: "At least 8 characters",
      newLbl: "Enter your new password",
      newHint: "At least 8 characters",
      submitBtn: "Submit",
      success: {
        summary: "Success",
        detail: "Your password updated successfully.",
      },
      failed: {
        summary: "Failed",
        detail: "Failed to change your password, please check your inputs, and try again.",
      },
    },
    closeBtn: "Close",
    verifyEmail: {
      info: {
        summary: "Info",
        detail: "A 6-digit code has been sent to your email.",
      },
      failed: {
        summary: "Failed",
        detail: "Failed to send verification code, please try again.",
      },
      confirm: {
        success: {
          summary: "Success",
          detail: "Failed to verify the code, please check the code, and try again.",
        },
      },
    },
  },
  search: {
    placeholder: "Search ...",
    inProcessDesigns: "in Process Designs",
    inObjects: "in Players",
  },
  processDesigner: {
    fixValidationIssues: "Please fix following issue(s):",
    titlePlaceholder: "Type design title",
    activitiesHeader: "Activity",
    processFlowHeader: "Process Flow",
    columnGroup: "C/G",
    rowGroup: "R/G",
    allocationsHeader: "Allocation",
    objectTypeHeader: "Object Type",
    objectHeader: "Player",
    trackTypeHeader: "Track Type",
    glAccountHeader: "G/L Account",
    activityPlaceholder: "Activity {i}",
    trackTypePlaceholder: "Track Type {i}",
    noObject: {
      text: "{addObject} by dragging one from the left sidebar",
      addObject: "Add an object",
    },
    noDesign: {
      text: "{selectDesign} from the list, or {createOne}",
      selectDesign: "Select a design",
      createOne: "create one",
    },
    list: {
      failed: {
        summary: "Failed",
        detail: "Failed to load process designs, please reload the page.",
      },
    },
    create: {
      failed: {
        summary: "Failed",
        detail: "Failed to create a new process design, please try again.",
      },
    },
    addPlayer: {
      failed: {
        summary: "Gescheitert",
        detail:
          "Es konnte kein neuer Player für den Ordner Defaults erstellt werden. Bitte wählen Sie einen anderen Ort.",
      },
    },
    load: {
      failed: {
        summary: "Failed",
        detail: "Failed to load the process design, please try again.",
      },
    },
    save: {
      failed: {
        summary: "Failed",
        detail: "Failed to save process design changes, please try again.",
      },
    },
    delete: {
      failed: {
        summary: "Failed",
        detail: "Failed to delete the process design, please try again.",
      },
    },
    duplicatePlayer: {
      failed: {
        summary: "Player already exists",
        detail: "Failed to add the player, please try another one.",
      },
    },
    otherProcPlayer: {
      failed: {
        summary: "Other process players not allowed.",
        detail: "The player is neither part of the default players nor the process players.",
      },
    },
    arrow: {
      maxCount: "Entschuldigung! Du kannst nicht mehr als 3 Pfeile für eine Aktivität haben.",
      noSelectedArrow: "Select an arrow to delete.",
      notSameArrow: "Select arrow from the current row.",
    },
  },
  select: {
    modules: {
      duplicate: {
        summary: "Duplicate Module Abbreviation",
        detail: "A module with this abbreviation already exists please try another",
      },
    },
    objectTypes: {
      duplicate: {
        summary: "Duplicate Object Type Title",
        detail: "An object type with this title already exists please try another",
      },
      moduleNotSelected: {
        summary: "Module Not Selected",
        detail: "Please Select a Module",
      },
    },
    objects: {
      duplicate: {
        summary: "Duplicate Object Title",
        detail: "An object with this title already exists please try another",
      },
      moduleNotSelected: {
        summary: "Module or Object Type Not Selected",
        detail: "Please select a module and object type",
      },
    },
  },
  processFlow: {
    zoomLevel: "{value}",
    message: {
      summary: "",
      detail: "To become beta tester of process flow, contact kyra support kyra@kern.ag",
    },
    contact: "kyra@kern.ag",
  },
  teams: {
    createTeam: "Create Team ({teamsFilter})",
    addMember: "Add Member ({membersFilter})",
    myPrivateDesigns: "My Private Designs",
    list: {
      failed: {
        summary: "Failed",
        detail: "Failed to load teams, please reload the page.",
      },
    },
    create: {
      failed: {
        summary: "Failed",
        detail: "Failed to create a new team, please try again.",
      },
    },
    update: {
      failed: {
        summary: "Failed",
        detail: "Failed to update the team, please try again.",
      },
    },
    delete: {
      failed: {
        summary: "Failed",
        detail: "Failed to delete the team, please try again.",
      },
    },
  },
  names: {
    design: {
      1: "Ship Painting",
      2: "Food Knitting",
      3: "Plant Ordering",
      4: "House Checking",
      5: "Health Renting",
      6: "Product Drawing",
      7: "Company Repairing",
      8: "Tie Replacing",
      9: "Car Sketching",
      10: "Book Folding",
      11: "Finance Jumping",
      12: "Money Tasting",
      13: "Hotel Sprinting",
      14: "Target Booking",
      15: "Mail Riding",
      16: "Leader Diving",
      17: "Vacation Growing",
    },
    activity: {
      1: "Jumping",
      2: "Sailing",
      3: "Glueing",
      4: "Sticking",
      5: "Riding",
      6: "Cooking",
      7: "Baking",
      8: "Seasoning",
      9: "Stirring",
      10: "Snorkeling",
      11: "Climbing",
      12: "Closing",
      13: "Opening",
      14: "Planning",
      15: "Discussing",
      16: "Driving",
      17: "Raiding",
      18: "Decorating",
    },
  },
  homePage: {
    processDesigns: "Process Designs",
    contact: "Kontakt",
    signIn: "Anmelden",
    header: {
      part1: "Dein Digitaler",
      part2: "Prozessmanager",
    },
    tryBtn: "Kostenlos testen",
    message: [
      "Einfach. Dynamisch. Nachvollziehbar. So funktioniert Prozessgestaltung mit Kyra. Visualisieren Sie Ihre Arbeitsabläufe übersichtlich und professionell. Legen Sie sofort los.",
      "Mit dem Prozess-Tool Kyra kannst du Arbeitsabläufe ganz einfach abbilden und übersichtlich und professionell visualisieren. Gleich loslegen.",
      "Wir stellen Kyra vor: Ihr Schlüssel zu optimierten Arbeitsabläufen! Mit unserem leistungsstarken Prozessmapping-Tool können Sie Ihre Arbeitsabläufe einfach visualisieren und optimieren. Starten Sie jetzt und erleben Sie die Klarheit und Professionalität, die Kyra mit sich bringt.",
      "Entfalten Sie die Kraft von Kyra: Vereinfachen, rationalisieren, erfolgreich sein! Mit unserem dynamischen Prozessdesign-Tool können Sie Arbeitsabläufe erstellen, die einfach, verständlich und effizient sind. Beginnen Sie, Ihre Prozesse mit Kyra klar und professionell zu visualisieren. Beginnen Sie noch heute.",
      "Effizienz auf Knopfdruck mit Kyra! Mit unserem intuitiven Prozess-Tool können Sie Ihre Arbeitsabläufe nahtlos abbilden und optimieren. Verabschieden Sie sich von der Komplexität und begrüßen Sie die Klarheit, wenn Sie Ihre Arbeitsabläufe mit Kyra visualisieren. Starten Sie jetzt und erleben Sie die Einfachheit aus erster Hand.",
    ],
    disclosure: "Impressum",
  },
  impressumPage: {
    title: "Impressum",
    signIn: "Anmelden",
    contact: "Kontakt",
    address: "Anschrift",
    moreInfo: "Mehr Informationen",
    phone: "Telefon",
    chairman: "Vorstand",
    register: "Handelsregister",
    taxId: "Umsatzsteuer-ID",
    disclosure: "Impressum",
    disclaimer: "Haftungshinweis",
    disclaimerMessage: `Die Kern AG weist daraufhin, dass verlinkte Seiten nicht von ihr stammen. Soweit diese einen illegalen oder
    unrichtigen Inhalt haben, distanziert sich die Kern AG ausdrücklich davon. Die Kern AG will nur legale und
    fehlerfreie Sachverhalte und Informationen wiedergeben und hat keinen Einfluss auf die Gestaltung und den
    Inhalt von verlinkten Seiten. Diese stellen auch keine Erweiterung oder Ergänzung der eigenen Informationen
    dar. Die Kern AG ist nicht in der Lage, alle Veränderungen auf den verlinkten Seiten regelmäßig zu verfolgen.
    Aus diesem Grunde wird jegliche Verantwortung und Haftung für die Inhalte alle dieser verlinkten Informationen
    abgelehnt. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der
    Nutzung solcher Art dargebotener Informationen entstehen, haftet ausschließlich der jeweilige Anbieter jener
    Seite, auf welche verwiesen wird.`,
  },
  goTo: {
    select: {
      title: "Gehe zu/Auswählen",
      hint: "Geben Sie eine Zellkoordinate ein, um dorthin zu gelangen, oder zwei, um einen Bereich auszuwählen.",
    },
    shortcuts: {
      title: "Abkürzungen",
      teams: "Mannschaften",
      players: "Spieler",
      player: "Player",
      processes: "Prozesse",
      settings: "Einstellungen",
      tools: "Werkzeuge",
      styles: "Styles",
    },
    favorites: {
      title: "Favoriten",
      noFavorites: "Kein bevorzugter Prozess",
    },
    range: {
      row: "Row",
      col: "Col",
    },
    standard: {
      title: "Standard",
    },
  },
  fill: {
    down: "Down",
    up: "Up",
    right: "Right",
    left: "left",
  },
  actions: {
    ok: "OK",
    confirm: "Bestätigen Sie",
    cancel: "Kündigen",
    save: "Speichern Sie",
    add: "Add",
    update: "Update",
  },
  sidebar: {
    processes: "Prozesse",
    players: "Spieler",
    teams: "Mannschaften",
    styles: {
      title: "Stile",
      menu: {
        styles: "Stile",
        templates: "Templates",
        advanced: "Advanced",
      },
    },
    newProcess: "Neues Verfahren",
    newPostingProcess: "Neuer Buchungsprozess",
    newGrid: "New Grid",
    newFolder: "Neue Mappe",
    newPlayer: "Neuer Spieler",
    newTeam: "Neue Mannschaft",
    settings: "Einstellungen",
    delete: {
      player: {
        title: "Spieler löschen",
        description: "Sind Sie sicher, dass Sie diesen Player löschen möchten?",
      },
      team: {
        title: "Spieler löschen",
        description: "Sind Sie sicher, dass Sie diesen Player löschen möchten?",
      },
      process: {
        title: "Prozess löschen",
        description: "Sind Sie sicher, dass Sie diesen Prozess löschen wollen?",
      },
      view: {
        title: "Delete View",
        description: "Are you sure you want to delete this View?",
      },
      sheet: {
        title: "Delete Sheet",
        description: "Are you sure you want to delete this Sheet?",
      },
    },
    trash: {
      emptyMessage: "Der Papierkorb ist leer",
      selectAll: "Alle auswählen",
    },
    sheets: {
      title: "Blätter",
    },
  },
  contextMenu: {
    activity: {
      addRowGro: "Add Row Group",
      addColGro: "Add Column Group",
      addColumn: "Add Column",
      addRow: "Add Row",
      removeRow: "Remove Row",
      addArrow: "Add Arrow",
      removeCol: "Remove Column",
      moveLeft: "Move Left Ctrl+Left",
      moveRight: "Move Right Ctrl+Right",
      moveUp: "Move Up Ctrl+Up",
      moveDown: "Move Down Ctrl+Down",
      deleteArrow: "Delete selected arrow",
    },
    open: "Öffnen Sie",
    new: "Neu",
    edit: "bearbeiten",
    rename: "Umbenennen",
    expandCollapse: "Erweitern/Kollabieren",
    move: "Verschieben",
    moveUp: "Aufsteigen",
    moveDown: "Nach unten bewegen",
    moveOneLevelUp: "Eine Ebene höher gehen",
    moveOneLevelDown: "Eine Ebene tiefer gehen",
    delete: "Löschen",
    cut: "Schnitt",
    copy: "Kopieren",
    goto: "Gehe zu",
    paste: "Kleister",
    apply: "Bewerbung",
    modify: "Ändern Sie",
    duplicate: "Duplizieren Sie",
    normalProcess: "Normaler Prozess",
    postingProcess: "Buchungsprozess",
    grid: "Grid",
    folder: "Mappe",
    addPlayer: "Spieler hinzufügen",
    newMember: "Neues Mitglied",
    sendMail: "Mail senden",
    changeRole: "Rolle ändern",
    addMember: "Add A Member",
    styles: "Stile",
    update: "Update",
    Email: "E-mail",
  },
  views: {
    navigationCorner: "Navigationsecke",
    dataView: "Datenansicht",
    styleCorner: "Stil-Ecke",
    title: "Title",
    addNewView: "Add A New View",
    addView: "Add View",
    editView: "Edit View",
    icons: {
      icon: "Icon",
      bag: "Bag",
      home: "Home",
      chart: "Chart",
      chair: "Chair",
      ruler: "Ruler",
      document: "Document",
      dollar: "Dollar",
      euro: "Euro",
      filter: "Filter",
      airPlane: "Airplane",
      car: "Car",
      toolBox: "toolBox",
      flag: "Flag",
    },
  },
  processDesign: {
    accessToolbar: {
      moreCommands: {
        title: "Customize the Quick Access Toolbar",
        source: "Source",
        target: "Target",
      },
    },
  },
  404: {
    snakes: "404 Snakes !",
    notFound: "Page not found",
    backToDes: " Back to Designs",
  },
  title: {
    undo: "Änderungen rückgängig machen",
    redo: "Änderungen wiederherstellen",
    favoriteProcess: "Bevorzugten Prozess einstellen",
    setView: "Ansicht einstellen",
    setNavigationView: "Navigationsansicht einstellen",
    commentTitle: "Kommentar hinzufügen oder bearbeiten",
    cut: "Ausschneiden",
    copy: "Kopieren",
    fill: "Füllen",
    showCustomStyle: "Benutzerdefinierte Stile anzeigen",
    deleteComment: "Kommentar löschen",
    screenshot: "P drücken, um Bildschirmfoto zu machen",
    mergeCell: "Zellen zusammenführen",
    expandCollapseWithShortcut: "Strg + Shift + F11 zum Erweitern oder Reduzieren",
    paste: "Einfügen",
    basicFormatting: "Grundlegende Formatierung",
    processFlow: "Prozessablauf",
  },
};
