import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import authComponent from "../components/Auth.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { auth: true },
  },
  {
    path: "/auth",
    name: "Auth",
    component: authComponent,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;


export const navigateTo = (param) => {
  if (typeof param == "string") {
    param = { path: param };
  }

  if (router.currentRoute.path != param.path) {
    if (param.replace) router.replace(param);
    else router.push(param);
  }
};