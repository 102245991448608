<template>
  <div>
    <custom-modal modal-width="25rem" body-padding="0 24px 20px" @close="closeModal">
      <template #header>
        Subprojects are part of pro plan
      </template>

      <template #body>
        <button>
          <pay-pal></pay-pal>
        </button>
      </template>

      <template #footer>
        <!-- <custom-button
          :button-class="{ 'btn btn-error': true, 'focused-btn-error': focusedButton === 'cancel' }"
          @click="closeModal()"
        >
          cancel
        </custom-button> -->
        <custom-button
          :button-class="{ 'btn btn-info': true, 'focused-btn-info': focusedButton === 'confirm' }"
          @click="confirmAction"
        >
          Cancel
        </custom-button>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import hotkeys from "hotkeys-js";
import { HotkeyScopes } from "../../utils/constants";
import CustomButton from "./CustomButton.vue";
import CustomModal from "./CustomModal.vue";
import PayPal from "../PayPal.vue";

export default {
  components: {
    CustomButton,
    CustomModal,
    PayPal,
  },
  data() {
    return {
      previousHotkeyScope: null,
      focusedButton: "confirm", // Default focus on confirm button
    };
  },
  computed: {
    deleteDialog() {
      return this.$store.state.deleteDialog;
    },
  },
  mounted() {
    this.bindScope();
  },
  beforeUnmount() {
    hotkeys.unbind("enter, tab, escape, right, left,shift+tab", HotkeyScopes.DeleteConfirmDialog);
  },
  methods: {
    resetScope() {
      if (hotkeys.getScope() === HotkeyScopes.DeleteConfirmDialog) {
        // this.$store.dispatch("setScope", this.previousHotkeyScope ?? HotkeyScopes.All);
        hotkeys.unbind("enter, tab, escape, right, left,shift+tab", HotkeyScopes.DeleteConfirmDialog);
      }
    },
    bindScope() {
      if (hotkeys.getScope() !== HotkeyScopes.DeleteConfirmDialog) {
        this.previousHotkeyScope = hotkeys.getScope();
        // this.$store.dispatch("setScope", HotkeyScopes.DeleteConfirmDialog);
      }
      hotkeys("enter, tab, escape, right, left,shift+tab", HotkeyScopes.DeleteConfirmDialog, (e, handler) => {
        e.preventDefault();

        switch (handler.key) {
          case "escape":
            this.closeModal();
            break;
          case "right":
          case "left":
          case "tab":
          case "shift+tab":
            this.toggleFocus();
            break;
          case "enter":
            // if (this.showDialog) {
            this.triggerFocusedButtonAction();
            // }
            break;
        }
      });
    },
    closeModal() {
      this.focusedButton = "confirm";
      this.$store.dispatch("setDeleteDialog", {
        show: false,
        ids: null,
        title: null,
        description: null,
        itemType: null,
      });
      //   this.resetScope();
    },
    confirmAction() {
      //   if (this.deleteDialog.action) {
      // this.deleteDialog.action(); // Call the action provided in the dialog state
      //   }
      this.closeModal();
    },
    toggleFocus() {
      this.focusedButton = this.focusedButton === "cancel" ? "confirm" : "cancel";
    },
    triggerFocusedButtonAction() {
      if (this.focusedButton === "cancel") {
        this.closeModal();
      } else {
        this.confirmAction();
      }
    },
  },
};
</script>

<style scoped>
.focused-btn-error {
  background-color: #ff525228;
}

.focused-btn-info {
  background-color: #1976d226;
}
</style>
